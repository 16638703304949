import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';

import {useQuery} from '@tanstack/react-query';
import {
  RQ_CREATE_JOB_ROLE_API,
  RQ_GET_JOB_ROLE_BY_ID_API,
  RQ_GET_JOB_ROLE_LIST_API,
  RQ_GET_SEARCH_JOB_ROLE_API,
  RQ_UPDATE_JOB_ROLE_ALL_FIELDS_API,
} from './constants';

export const RQPostCreateJobRole = async ({
  jobRole,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_JOB_ROLE_API,
    JSON.stringify({...jobRole}),
    token,
    null,
    signal,
  );
};

export const RQUseGetJobRoleListData = payload => {
  return useQuery(
    ['rq_get_job_role_list_data', payload],
    async ({queryKey, signal}) => {
      const {token} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_JOB_ROLE_LIST_API,
        null,
        token,
        null,
        signal,
      );
    },
    {
      cacheTime: 2 * 60 * 1000, // 2 min
    },
  );
};

export const RQUseGetJobRoleByIdData = payload => {
  return useQuery(
    ['rq_get_job_role_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_JOB_ROLE_BY_ID_API,
        null,
        token,
        {id:id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQPutJobRoleUpdateAllField = async ({
  jobRole,
  token,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_JOB_ROLE_ALL_FIELDS_API,
    JSON.stringify({...jobRole, id:id}),
    token,
    null,
    signal,
  );
};

export const RQUseGetSearchJobRoleData = payload => {
  return useQuery(
    ['rq_get_search_job_role_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_SEARCH_JOB_ROLE_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};
