import React from 'react';
import {NavLink} from 'react-router-dom';
import {Typography} from 'antd';
import {
  ArrowsAltOutlined,
  BarChartOutlined,
  BoldOutlined,
  EuroCircleOutlined,
  FileTextOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PoundCircleOutlined,
  PullRequestOutlined,
  RiseOutlined, SearchOutlined,
  SmallDashOutlined,
  TagOutlined,
  ThunderboltOutlined,
  ToTopOutlined,
  TrademarkOutlined,
  UploadOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';

const MenuBuilder = menus => {
  let listOfMenus=  [];
  menus.forEach(menu => {
    if (menu.children.length > 0) {
      listOfMenus.push(convertToMenu(menu));
      menu.children.forEach(child =>
        listOfMenus.push(convertToMenu(child)),
      );
    }
  });
  return listOfMenus;
};

const convertToMenu = menu =>{
  return {
    key: menu.key,
    icon: getIcon(menu),
    label: (
      <NavLink to={`${menu.path}`}
        className={({isActive, isPending}) =>
          isActive
            ? 'active'
            : isPending
              ? 'pending'
              : ''}
      >
        {generateLabel(menu)}
      </NavLink>
    ),
  };
};

const generateLabel =menu => {
  if (menu.path && menu.path !== '')
  {return menu.label;}
  else {
    return (
      <Typography.Title level={5}>{menu.label}</Typography.Title>
    );
  }
};
const getIcon = menu => {
  switch (menu.iconName) {
    case 'ArrowsAltOutlined':  return <ArrowsAltOutlined />;
    case 'BarChartOutlined': return <BarChartOutlined />;
    case 'BoldOutlined':  return <BoldOutlined />;
    case 'EuroCircleOutlined':  return <EuroCircleOutlined />;
    case 'FileTextOutlined':  return <FileTextOutlined />;
    case 'MenuFoldOutlined':  return <MenuFoldOutlined />;
    case 'MenuUnfoldOutlined':  return <MenuUnfoldOutlined />;
    case 'PoundCircleOutlined':  return <PoundCircleOutlined />;
    case 'RiseOutlined':  return <RiseOutlined />;
    case 'SearchOutlined':  return <SearchOutlined />;
    case 'TagOutlined':  return <TagOutlined />;
    case 'ThunderboltOutlined':  return <ThunderboltOutlined />;
    case 'ToTopOutlined':  return <ToTopOutlined />;
    case 'TrademarkOutlined':  return <TrademarkOutlined />;
    case 'UploadOutlined':  return <UploadOutlined />;
    case 'UsergroupAddOutlined':  return <UsergroupAddOutlined />;
    case 'UserAddOutlined': return <UserAddOutlined />;
    case 'MediumOutlined': return <SmallDashOutlined />;
    case 'PullRequestOutlined': return <PullRequestOutlined />;
    default:  return <></>;
  }
};

export default MenuBuilder;
