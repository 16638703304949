export const RQ_CREATE_APPROVAL_CHAIN_DEFINITION_API = {
  url: 'v2/approval-chain-definition/create',
  method: 'POST',
  auth: true,
};

export const RQ_GET_APPROVAL_CHAIN_DEFINITION_LIST_API = {
  url: 'v2/approval-chain-definition/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_APPROVAL_CHAIN_DEFINITION_BY_ID_API = {
  url: 'v2/approval-chain-definition/findById/<%= id %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_UPDATE_APPROVAL_CHAIN_DEFINITION_ALL_FIELDS_API = {
  url: 'v2/approval-chain-definition/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_SEARCH_APPROVAL_CHAIN_DEFINITION_API = {
  url: 'v2/approval-chain-definition/search?',
  method: 'GET',
  search: true,
  auth: true,
};
