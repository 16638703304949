import {
  CREATE_BUDGET_FAIL,
  CREATE_BUDGET_SUCCESSFUL, PULL_BACK_FAIL, PULL_BACK_SUCCESSFUL,
  UPDATE_BUDGET_FAIL,
  UPDATE_BUDGET_SUCCESSFUL,
} from '../actionConstants';

export const createBudgetSuccessful = payload => dispatch => {
  dispatch({
    type: CREATE_BUDGET_SUCCESSFUL,
    payload: payload,
  });
};

export const createBudgetFail = payload => dispatch => {
  dispatch({
    type: CREATE_BUDGET_FAIL,
    payload: payload,
  });
};

export const updateBudgetSuccessful = payload => dispatch => {
  dispatch({
    type: UPDATE_BUDGET_SUCCESSFUL,
    payload: payload,
  });
};

export const updateBudgetFail = payload => dispatch => {
  dispatch({
    type: UPDATE_BUDGET_FAIL,
    payload: payload,
  });
};

export const pullBackSuccessful = payload => dispatch => {
  dispatch({
    type: PULL_BACK_SUCCESSFUL,
    payload: payload,
  });
};

export const pullBackFail = payload => dispatch => {
  dispatch({
    type: PULL_BACK_FAIL,
    payload: payload,
  });
};
