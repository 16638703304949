export const parseNdjsonData = data => {
  if (typeof data === 'object') {
    return [data];
  }

  if (typeof data !== 'string') {
    throw new Error('Unexpected nd-json data type!');
  }
  const rows = data.split(/\n|\n\r/).filter(Boolean);
  return rows.map(row => JSON.parse(row));
};

export const buildHttpRequestHeader = (
  headers = {},
  isAuth = false,
  token = '',
  contentType = 'application/json',
) => {
  headers['Content-Type'] = contentType;
  if (isAuth) {
    headers.Authorization = token;
  }

  return headers;
};
