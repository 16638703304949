import React, {Suspense, lazy} from 'react';
import {ROUTE_ADD_PULL_BACK_BUDGET, ROUTE_PULL_BACK_BUDGET} from '../../RoutePathConstant';
import {authLoader} from '../../loader';
import {Spin} from 'antd';
import {AddPullBackRequest} from '../../../screensComponents/PullBackRequestScreen/AddPullBack';

const PullBackRequest = lazy( () =>
  import('../../../screens/PullBackRequest').then(module => ({default: module.PullBackRequest})),
);

const PullBackRequestNavigation =[
  {
    path: ROUTE_PULL_BACK_BUDGET,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <PullBackRequest />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_PULL_BACK_BUDGET,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddPullBackRequest />
      </Suspense>
    ),
  },
];

export default PullBackRequestNavigation;
