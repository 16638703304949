import {rqCreateRequest} from './rqHttpsUtils';
import {RQ_DOCTOR_UPLOAD_API} from './constants';

export const RQPostDoctorUpload = async ({
  formData,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_DOCTOR_UPLOAD_API,
    JSON.stringify({...formData}),
    token,
    null,
    signal,
  );
};
