import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_DOCUMENTS, ROUTE_DOCUMENTS, ROUTE_UPDATE_DOCUMENTS, ROUTE_VIEW_DOCUMENTS} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const Documents = lazy(() =>
  import('../../../screens/Documents')
    .then(module => ({default: module.Documents})));

const CreateDocuments = lazy(() =>
  import('../../../screens/Documents')
    .then(module => ({default: module.CreateDocuments})));

const UpdateDocument = lazy(() =>
  import('../../../screens/Documents')
    .then(module => ({default: module.EditDocument})));

const ViewDocument = lazy(() =>
  import('../../../screens/Documents')
    .then(module => ({default: module.ViewDocument})));

const DocumentsNavigation = [
  {
    path: ROUTE_DOCUMENTS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Documents />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_DOCUMENTS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateDocuments />
      </Suspense>
    ),
  },
  {
    path: ROUTE_UPDATE_DOCUMENTS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UpdateDocument />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_DOCUMENTS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewDocument />
      </Suspense>
    ),
  },
];

export default DocumentsNavigation;
