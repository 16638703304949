//import {ROUTE_CLOSE_EVENT, ROUTE_REJECTED_CLOSE_EVENT, ROUTE_VIEW_CLOSE_EVENT} from '../../RoutePathConstant';
const CloseEventNavigation = [
];
/*
const CloseEvent = lazy(() =>
  import('../../../screens/CloseEvent')
    .then(module => ({default: module.CloseEvent})));

const ViewCloseEvent = lazy(() =>
  import('../../../screens/CloseEvent')
    .then(module => ({default: module.ViewCloseEvent})));

const RejectedCloseEvent = lazy(() =>
  import('../../../screens/CloseEvent')
    .then(module => ({default: module.RejectedCloseEvent})));

const CloseEventNavigation = [
  {
    path: ROUTE_CLOSE_EVENT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CloseEvent />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_CLOSE_EVENT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewCloseEvent />
      </Suspense>
    ),
  },
  {
    path: ROUTE_REJECTED_CLOSE_EVENT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <RejectedCloseEvent />
      </Suspense>
    ),
  },
]; */

export default CloseEventNavigation;
