import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_REPORT_DOWNLOAD} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const ReportDownload = lazy(() =>
  import('../../../screens/ReportDownload')
    .then(module => ({default: module.ReportDownload})));

const ReportDownloadNavigation = [
  {
    path: ROUTE_REPORT_DOWNLOAD,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ReportDownload />
      </Suspense>
    ),
  },
];

export default ReportDownloadNavigation;
