import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_PAYMENT_UPLOAD} from '../../RoutePathConstant';

const PaymentUpload = lazy(() =>
  import('../../../screens/PaymentUpload')
    .then(module => ({default: module.PaymentUpload})));

const PaymentUploadNavigation = [
  {
    path: ROUTE_PAYMENT_UPLOAD,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <PaymentUpload />
      </Suspense>
    ),
  },
];

export default PaymentUploadNavigation;
