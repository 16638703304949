import {get, isEmpty} from 'lodash';
import {createRequest} from './httpUtils';
import {USER_LOGOUT_API, USER_PRIVILEGES_API, USER_PROFILE_API, USER_PROFILE_UPDATE_API} from './constants';

export const logoutUser = payload => {
  if (isEmpty(get(payload, 'a4337361-0057-48f7-a58f-9c59186b0431'))) {
    return invalidLogout(payload);
  }
  return createRequest(
    USER_LOGOUT_API,
    {},
    payload.token,
    {
      userId: get(payload, 'a4337361-0057-48f7-a58f-9c59186b0431', 'INVALID_USER'),
    },
  );
};

export const invalidLogout = payload => {
  return createRequest(
    USER_LOGOUT_API,
    {},
    payload?.token,
    {
      userId: 'invalid',
    },
  );
};

export const getUserProfileAPI = payload => {
  return createRequest(
    USER_PROFILE_API,
    null,
    payload.token,
    {userName: payload?.user?.userName},
  );
};

export const getPrivilegesForUserAPI = payload => {
  return createRequest(
    USER_PRIVILEGES_API,
    null,
    payload.token,
    {userName: payload?.user?.userName},
  );
};

export const updateUserProfileAPI = payload => {
  return createRequest(
    USER_PROFILE_UPDATE_API,
    {...payload.user},
    payload.token,
    null,
  );
};
