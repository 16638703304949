import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_EDIT_VIEW_REQUEST, ROUTE_VIEW_REQUEST} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const ViewRequest = lazy(() =>
  import('../../../screens/ViewRequest')
    .then(module => ({default: module.ViewRequest})));

const EditRequest = lazy(() =>
  import('../../../screens/ViewRequest')
    .then(module => ({default: module.EditRequest})));

const ViewRequestNavigation = [
  {
    path: ROUTE_VIEW_REQUEST,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewRequest />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_VIEW_REQUEST,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditRequest />
      </Suspense>
    ),
  },
];

export default ViewRequestNavigation;
