import {ExclamationCircleOutlined} from '@ant-design/icons';
import {Button, Modal} from 'antd';
import PropTypes from 'prop-types';

const ConfirmComponent = ({
  onConfirm,
  confirmMessage,
  titleMessage,
  confirmButtonType = 'primary',
  confirmButtonClassName = '',
  children,
  confirmStyle= {},
  confirmId = '',
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const confirm = () => {
    modal.confirm({
      title: titleMessage,
      icon: <ExclamationCircleOutlined />,
      content: confirmMessage,
      onOk: onConfirm,
    });
  };

  return (
    <>
      <Button
        onClick={confirm}
        type={confirmButtonType}
        className={confirmButtonClassName}
        style={confirmStyle}
        id={confirmId}
      >
        {children}
      </Button>
      {contextHolder}
    </>
  );
};

ConfirmComponent.propTypes = {
  onConfirm: PropTypes.func,
  titleMessage: PropTypes.string,
  confirmMessage: PropTypes.string,
  confirmButtonType: PropTypes.string,
  confirmButtonClassName: PropTypes.string,
  children: PropTypes.any,
  confirmStyle:PropTypes.object,
  confirmId: PropTypes.string,
};

export default ConfirmComponent;
