export const RQ_GET_MENUS_USER_ID_API = {
  url: 'v2/menu/for-user/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_FISCAL_YEAR_API = {
  url: 'v2/app-configuration/fiscalYears',
  method: 'GET',
  auth: true,
};
