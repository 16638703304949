import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {
  RQ_APPROVE_REJECT_EVENT_API,
  RQ_BULK_APPROVE_EVENT,
  RQ_CAN_CREATE_NEW_EVENT,
  RQ_CREATE_EVENT_CREATION_API,
  RQ_EVENT_UPDATE_API,
  RQ_EXTEND_CLOSURE_DATE, RQ_FT_APPROVE_REJECT_EVENT_API,
  RQ_GET_EVENT_BILL_INFO_BY_EVENT_ID_API,
  RQ_GET_EVENT_CREATION_API,
  RQ_GET_EVENT_CREATION_FIND_BY_API,
  RQ_GET_EVENT_CREATION_LAST_FY_EVENTS_API,
  RQ_GET_EVENT_PAYMENT_INFO_BY_EVENT_ID_API,
  RQ_GET_EVENT_SPEAKER_DETAILS_BY_ID_API,
  RQ_GET_PAYMENTS_API,
  RQ_MARK_ABORTED_BY_ID_API,
  RQ_MARK_CONDUCTED_BY_ADMIN_API,
  RQ_MARK_CONDUCTED_BY_ID_API,
  RQ_MARK_RESCHEDULED_BY_ID_API,
  RQ_REPORT_EVENT_CLOSURE,
  RQ_SAVE_PAYMENTS_API,
  RQ_SAVE_VENDOR_PAYMENTS_API,
  RQ_UPDATE_ADVANCE_API,
  RQ_VALIDATE_EVENT_API,
} from './constants';
import {useQuery} from '@tanstack/react-query';

export const RQPostCreateEventCreation = async ({
  eventCreation,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_EVENT_CREATION_API,
    JSON.stringify({...eventCreation}),
    token,
    null,
    signal,
  );
};

export const RQUseGetEventCreationData = payload => {
  return useQuery(
    ['rq_get_event_creation_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_EVENT_CREATION_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQUseGetPaymentData = (payload, options={}) => {
  return useQuery(
    ['rq_get_payment_data', payload],
    async ({queryKey, signal}) => {
      const {token, id} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_PAYMENTS_API,
        null,
        token,
        {id: id},
        signal,
      );
    },
    {
      ...options,
      cacheTime: 0,
    },
  );
};

export const RQEventApproveReject = async ({
  details,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_APPROVE_REJECT_EVENT_API,
    JSON.stringify({...details}),
    token,
    null,
    signal,
  );
};

export const RQFtEventApproveReject = async ({
  eventData,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_FT_APPROVE_REJECT_EVENT_API,
    JSON.stringify({...eventData}),
    token,
    null,
    signal,
  );
};
export const RQEventValidate = async ({
  id,
  details,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_VALIDATE_EVENT_API,
    JSON.stringify({...details}),
    token,
    {id: id},
    signal,
  );
};

export const RQEventPostPayment = async ({
  id,
  payments,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_SAVE_PAYMENTS_API,
    JSON.stringify(payments),
    token,
    {id: id},
    signal,
  );
};

export const RQVendorEventPostPayment = async ({
  id,
  payments,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_SAVE_VENDOR_PAYMENTS_API,
    JSON.stringify(payments),
    token,
    {id: id},
    signal,
  );
};

export const RQUpdatePaymentInfo = async ({
  id,
  details,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_ADVANCE_API,
    JSON.stringify({...details}),
    token,
    {id: id},
    signal,
  );
};

export const RQReportEventClosure = async ({
  data,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REPORT_EVENT_CLOSURE,
    JSON.stringify({...data}),
    token,
    null,
    signal,
  );
};

export const RQReportReSubmitEvent = async ({
  data,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_EVENT_UPDATE_API,
    JSON.stringify({...data}),
    token,
    null,
    signal,
  );
};

export const RQUseGetEventCreationByIdData = (payload, options={}) => {
  return useQuery(
    ['rq_get_event_creation_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EVENT_CREATION_FIND_BY_API,
        null,
        token,
        {id: id},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};
export const RQUseGetEventCreationBillInfoByIdData = (payload, options={}) => {
  return useQuery(
    ['rq_get_event_creation_bill_info_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, eventType, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EVENT_BILL_INFO_BY_EVENT_ID_API,
        null,
        token,
        {id: id, eventType: eventType},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQUseGetEventCreationSpeakerByIdData = (payload, options={}) => {
  return useQuery(
    ['rq_get_event_creation_speaker_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EVENT_SPEAKER_DETAILS_BY_ID_API,
        null,
        token,
        {id: id},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQEventMarkConducted = async ({
  id,
  conductedDateTime,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_MARK_CONDUCTED_BY_ID_API,
    {},
    token,
    {eventId: id, conductedDateTime: conductedDateTime},
    signal,
  );
};

export const RQEventMarkConductedByAdmin = async ({
  data,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_MARK_CONDUCTED_BY_ADMIN_API,
    data,
    token,
    {},
    signal,
  );
};

export const RQExtendEventClosureDate = async ({
  data,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_EXTEND_CLOSURE_DATE,
    data,
    token,
    {},
    signal,
  );
};

export const RQEventMarkReschedule = async ({
  id,
  rescheduledDateTime,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_MARK_RESCHEDULED_BY_ID_API,
    {},
    token,
    {eventId: id, rescheduledDateTime : rescheduledDateTime},
    signal,
  );
};

export const RQEventMarkAborted = async ({
  id,
  abortedReason,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_MARK_ABORTED_BY_ID_API,
    {abortedReason: abortedReason},
    token,
    {eventId: id},
    signal,
  );
};

export const RQCanCreateEvent =  (payload, options = {}) => {
  return useQuery(
    ['rq_can_create_event', payload],
    async ({queryKey, signal}) => {
      const {locationId, token} = queryKey[1];

      return rqCreateRequest(
        RQ_CAN_CREATE_NEW_EVENT,
        null,
        token,
        {locationId: locationId},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQUseGetEventCreationPaymentInfoByIdData = (payload, options={}) => {
  return useQuery(
    ['rq_get_event_creation_payment_info_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, eventType, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EVENT_PAYMENT_INFO_BY_EVENT_ID_API,
        null,
        token,
        {id: id, eventType: eventType},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQBulkApproveEvent = async ({
  id,
  eventList,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_BULK_APPROVE_EVENT,
    eventList,
    token,
    {},
    signal,
  );
};

export const RQUseGetEventCreationLastFYEventsData = (payload, options={}) => {
  return useQuery(
    ['rq_get_event_creation_last_fy_events_data', payload],
    async ({queryKey, signal}) => {
      const {userId, locationId, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EVENT_CREATION_LAST_FY_EVENTS_API,
        null,
        token,
        {userId: userId, locationId: locationId},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};
