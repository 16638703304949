export const RQ_GET_APPROVAL_COMMENT_LIST_API = {
  url: 'v2/reports/approval-comment-list/<%= ownerId %>/<%= processId %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_EXCEPTION_COMMENT_LIST_API = {
  url: 'v2/configuration-exception/findByEventId/<%= ownerId %>',
  method: 'GET',
  auth: true,
  search: true,
};
