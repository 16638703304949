import PropTypes from 'prop-types';
import {selectAuth} from '../../../redux/selectors';
import {connect} from 'react-redux';
import {Button, Col, Row, Space} from 'antd';
import React, {useState} from 'react';
import {SearchWalletScreen} from './SearchWallet';
import {useMutation} from '@tanstack/react-query';
import {RQPostSavePullBack, RQPostSearchWalletBalance} from '../../../reactQuery/apis';
import {SearchTable} from '../../../components/common/SearchTable';
import PullBackSearchColumns from './PullBackSearchColumns/PullBackSearchColumns';
import {pullBackFail, pullBackSuccessful} from '../../../redux/actions';
import {ROUTE_BASE_ROOT, ROUTE_PULL_BACK_BUDGET} from '../../../navigations/RoutePathConstant';
import {useNavigate} from 'react-router-dom';
import {LoadingSpinner} from '../../../components/common/LoadingSpinner';

const AddPullBackRequest = ({auth, handelSaveSuccess, handelSaveFail}) => {
  const [balanceList, setBalanceList] = useState([]);
  const [selectedBalanceList, setSelectedBalanceList] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const searchWalletBalance = useMutation(payload =>
    RQPostSearchWalletBalance(payload),
  {
    onSuccess: walletData => {
      setBalanceList(walletData);
    },
    onError: () => {},
  });

  const savePullBack = useMutation(payload =>
    RQPostSavePullBack(payload),
  {
    onSuccess: walletData => {
      handelSaveSuccess();
      setIsLoading(false);
      return navigate(ROUTE_BASE_ROOT + ROUTE_PULL_BACK_BUDGET);
    },
    onError: () => {
      setIsLoading(false);
      handelSaveFail();
    },
  });

  const updateSelectedWalletBalanceMap = (checkBoxEvent, balanceId) => {
    if(checkBoxEvent.target.checked === true) {
      setSelectedBalanceList([...selectedBalanceList, balanceId]);
    }else {
      setSelectedBalanceList(selectedBalanceList.filter(bal => bal !== balanceId));
    }
  };

  const approveAll = checkBoxEvent =>{
    setSelectedBalanceList([]);
    if(checkBoxEvent.target.checked === true) {
      let idList = [];
      balanceList.forEach( b =>{
        if(idList.indexOf(b.walletBalanceId) === -1) {
          idList.push(b.walletBalanceId);
        }
      });
      setSelectedBalanceList(idList);
    }
  };

  const saveBullBack = () =>{
    setIsLoading(true);
    savePullBack.mutate({
      body: {
        walletBalanceIdList: selectedBalanceList,
        locationId:auth.user.location.id,
        employeeId:auth.user.id,
        jobRoleId: auth.user.jobRole.id,
        fiscalYear: selectedFilters.fiscalYear,
      },
      token: auth?.token,
    });
  };
  const search = filter =>{
    if(filter.locationId === undefined){
      filter = {...filter, employeeId: auth.user.id, locationId: auth.user.location.id};
    }
    setSelectedFilters(filter);
    searchWalletBalance.mutate({
      filters: filter,
      token: auth?.token,
    });
  };

  return (
    <>
      {isLoading && (<LoadingSpinner />)}
      <div className={'main-pullBack-container'}>
        <Space
          className={'brand-space'}
          direction={'vertical'}
          size={'middle'}>
          <Row>
            <SearchWalletScreen searchPullBack={search} />
          </Row>
          <Row>
            <Col span={24}>
              <SearchTable
                data={balanceList}
                columns={PullBackSearchColumns(auth, updateSelectedWalletBalanceMap, selectedBalanceList, approveAll)}
                dataSource={balanceList}
              />
            </Col>
          </Row>
          <Button type={'primary'} onClick={saveBullBack}>
            Pull back
          </Button>
        </Space>
      </div>
    </>
  );
};

AddPullBackRequest.propTypes = {
  auth: PropTypes.object.isRequired,
  handelSaveSuccess: PropTypes.func.isRequired,
  handelSaveFail: PropTypes.func.isRequired,
};
const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

const action = {
  handelSaveSuccess: pullBackSuccessful,
  handelSaveFail: pullBackFail,
};

export default connect(mapState, action)(AddPullBackRequest);
