export const RQ_UPLOAD_WALLET_API = {
  url: 'v2/wallet-upload/upload',
  method: 'POST',
  auth: true,
};

export const RQ_WALLET_UPLOAD_SAVE_API = {
  url: 'v2/wallet-upload/save/<%= transactionId %>',
  method: 'POST',
  auth: true,
};

export const RQ_GET_SEARCH_WALLET_UPLOAD_API = {
  url: 'v2/wallet-upload-log/search?',
  method: 'GET',
  auth: true,
  search:true,
};

export const RQ_GET_WALLET_BALANCE_API = {
  url: 'v2/wallet-balance/balance/<%= fiscalYear %>/<%= locationId %>/<%= budgetType %>/<%= brandId %>',
  method: 'GET',
  auth: true,
  search:true,
};

export const RQ_POST_SERVICE_AGREEMENT_API = {
  url: 'v2/event-creation/uploadSignedDocument',
  method: 'POST',
  auth: true,
};

export const RQ_POST_GET_WALLET_BALANCE = {
  url: 'v2/wallet-balance/search-balance',
  method: 'POST',
  auth: true,
};

export const RQ_POST_SAVE_PULL_BACK = {
  url: 'v2/wallet-balance/save-pullBack',
  method: 'POST',
  auth: true,
};

export const RQ_GET_PULL_BACK_LIST_API = {
  url: 'v2/pull-back-request/search?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_PULL_BACK_TRANSACTION_DETAIL_API = {
  url: 'v2/pull-back-request/wallet-transaction-details?',
  method: 'GET',
  auth: true,
  search: true,
};
