import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {
  RQ_APPROVE_REALLOCATION_BY_ID_API,
  RQ_CREATE_REALLOCATION_API,
  RQ_FORWARD_REALLOCATION_BY_ID_API,
  RQ_GET_REALLOCATION_BY_ID_API,
  RQ_GET_REALLOCATION_LIST_API,
  RQ_GET_REALLOCATION_MY_PENDING_LIST_API,
  RQ_GET_REALLOCATION_PENDING_WITH_ME_LIST_API,
  RQ_REJECT_REALLOCATION_BY_ID_API,
} from './constants';
import {useQuery} from '@tanstack/react-query';

export const RQPostCreateReallocation = async ({
  reallocation,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_REALLOCATION_API,
    JSON.stringify({...reallocation}),
    token,
    null,
    signal,
  );
};

export const RQUseGetReallocationListData = payload => {
  return useQuery(
    ['rq_get_reallocation_list_data', payload],
    async ({queryKey, signal}) => {
      const {token} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_REALLOCATION_LIST_API,
        null,
        token,
        null,
        signal,
      );
    },
    {
      cacheTime: 2 * 60 * 1000, // 2 min
    },
  );
};

export const RQUseGetRequestsPendingWithMeData = payload => {
  return useQuery(
    ['rq_get_requests_pending_with_me_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_REALLOCATION_MY_PENDING_LIST_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQUseGetRequestsPendingMyApprovalData = payload => {
  return useQuery(
    ['rq_get_requests_my_approval_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_REALLOCATION_PENDING_WITH_ME_LIST_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQUseGetReallocationRequestByIdData = payload => {
  return useQuery(
    ['rq_get_reallocation_request_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_REALLOCATION_BY_ID_API,
        null,
        token,
        {id:id},
        signal,
      );

    },
    {
      cacheTime: 0,
    },
  );
};

export const RQRejectRequestField = async ({
  details,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REJECT_REALLOCATION_BY_ID_API,
    details,
    token,
    null,
    signal,
  );
};

export const RQApproveRequestField = async ({
  details,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_APPROVE_REALLOCATION_BY_ID_API,
    details,
    token,
    null,
    signal,
  );
};

export const RQForwardRequestField = async ({
  details,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_FORWARD_REALLOCATION_BY_ID_API,
    details,
    token,
    null,
    signal,
  );
};
