import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {
  ROUTE_ADD_FREEZE_ALLOCATION,
  ROUTE_FREEZE_ALLOCATION,
  ROUTE_UPDATE_FREEZE_ALLOCATION,
} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const FreezeAllocation = lazy(() =>
  import('../../../screens/FreezeAllocation')
    .then(module => ({default: module.FreezeScreen})));

const CreateFreeze = lazy(() =>
  import('../../../screens/FreezeAllocation')
    .then(module => ({default: module.CreateFreeze})));

const UpdateFreezeAllocation = lazy(() =>
  import('../../../screens/FreezeAllocation')
    .then(module => ({default: module.UpdateFreezeAllocation})));

const FreezeAllocationNavigation = [
  {
    path: ROUTE_FREEZE_ALLOCATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <FreezeAllocation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_FREEZE_ALLOCATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateFreeze />
      </Suspense>
    ),
  },
  {
    path: ROUTE_UPDATE_FREEZE_ALLOCATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UpdateFreezeAllocation />
      </Suspense>
    ),
  },
];

export default FreezeAllocationNavigation;
