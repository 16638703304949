import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_DOCTOR_UPLOAD} from '../../RoutePathConstant';

const DoctorUpload = lazy(() =>
  import('../../../screens/DoctorUpload')
    .then(module => ({default: module.DoctorUpload})));

const DoctorUploadNavigation = [
  {
    path: ROUTE_DOCTOR_UPLOAD,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <DoctorUpload />
      </Suspense>
    ),
  },
];

export default DoctorUploadNavigation;
