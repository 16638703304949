import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest} from './rqHttpsUtils';
import {RQ_GET_APPROVAL_COMMENT_LIST_API, RQ_GET_EXCEPTION_COMMENT_LIST_API} from './constants';

export const RQUseGetReportApprovalCommentList = payload => {
  return useQuery(
    ['rq_get_report_approval_comment_list', payload],
    async ({queryKey, signal}) => {
      const {ownerId, processId, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_APPROVAL_COMMENT_LIST_API,
        null,
        token,
        {ownerId: ownerId, processId: processId},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQUseGetExceptionCommentList = payload => {
  return useQuery(
    ['rq_get_exception_comment_list', payload],
    async ({queryKey, signal}) => {
      const {ownerId, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EXCEPTION_COMMENT_LIST_API,
        null,
        token,
        {ownerId: ownerId},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};
