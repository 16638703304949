import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_JOB_ROLE, ROUTE_JOB_ROLE, ROUTE_UPDATE_JOB_ROLE} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const Job = lazy(() =>
  import('../../../screens/Job')
    .then(module => ({default: module.Job})));

const AddJob = lazy(() =>
  import('../../../screens/Job')
    .then(module => ({default: module.AddJob})));

const UpdateJobRole = lazy(() =>
  import('../../../screens/Job')
    .then(module => ({default: module.UpdateJob})));

const JobRoleNavigation = [
  {
    path: ROUTE_JOB_ROLE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Job />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_JOB_ROLE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddJob />
      </Suspense>
    ),
  },
  {
    path: ROUTE_UPDATE_JOB_ROLE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UpdateJobRole />
      </Suspense>
    ),
  },
];

export default JobRoleNavigation;
