export const RQ_GET_ABM_LIST_API = {
  url: 'v1/user/my-team/<%= userId %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_USER_BY_JOBROLE_ID_API = {
  url: 'v1/user/getUserBy?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_BRAND_MANAGER_LIST_API = {
  url: 'v2/brand/for-user/<%= userId %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_POST_REPORT_PASSBOOK_API = {
  url: 'v2/reports/passbook-for-user',
  method: 'POST',
  auth: true,
};

export const RQ_POST_REPORT_PASSBOOK_DOWNLOAD_API = {
  url: '/v2/reports/passbook-for-user-download',
  method: 'POST',
  auth: true,
};
