import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_USER, ROUTE_EDIT_USER, ROUTE_USER} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const User = lazy(() =>
  import('../../../screens/User')
    .then(module => ({default: module.User})));

const AddUser = lazy(() =>
  import('../../../screens/User')
    .then(module => ({default: module.CreateUser})));

const UpdateUser = lazy(() =>
  import('../../../screens/User')
    .then(module => ({default: module.UpdateUser})));

const UserNavigation = [
  {
    path: ROUTE_USER,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <User />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_USER,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddUser />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_USER,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UpdateUser />
      </Suspense>
    ),
  },
];

export default UserNavigation;
