import {rqCreateRequest} from './rqHttpsUtils';
import {RQ_GET_DOCUMENT_BY_OWNER_LIST_API} from './constants/rqDocumentAPIConstants';
import {useQuery} from '@tanstack/react-query';

export const RQGetDocumentByOwner = (payload, options={}) => {
  return useQuery(
    ['rq_get_document_by_owner_id_data', payload],
    async ({queryKey, signal}) => {
      const {ownerId, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_DOCUMENT_BY_OWNER_LIST_API,
        null,
        token,
        {ownerId: ownerId},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};
