import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {
  RQ_CREATE_APPROVAL_CHAIN_DEFINITION_API,
  RQ_GET_APPROVAL_CHAIN_DEFINITION_BY_ID_API,
  RQ_GET_APPROVAL_CHAIN_DEFINITION_LIST_API,
  RQ_GET_SEARCH_APPROVAL_CHAIN_DEFINITION_API,
  RQ_UPDATE_APPROVAL_CHAIN_DEFINITION_ALL_FIELDS_API,
} from './constants';
import {useQuery} from '@tanstack/react-query';

export const RQPostApprovalChainConfiguration = async ({
  approvalChain,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_APPROVAL_CHAIN_DEFINITION_API,
    JSON.stringify({...approvalChain}),
    token,
    null,
    signal,
  );
};

export const RQUseGetApprovalChainListData = payload => {
  return useQuery(
    ['rq_get_approval_chain_list_data', payload],
    async ({queryKey, signal}) => {
      const {token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_APPROVAL_CHAIN_DEFINITION_LIST_API,
        null,
        token,
        null,
        signal,
      );
    },
    {
      cacheTime: 2 * 60 * 1000, // 2 min
    },
  );
};

export const RQUseGetApprovalChainByIdData = (payload, options={}) => {
  return useQuery(
    ['rq_get_approval_chain_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_APPROVAL_CHAIN_DEFINITION_BY_ID_API,
        null,
        token,
        {id:id},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQPutApprovalChainUpdateAllField = async ({
  approvalChain,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_APPROVAL_CHAIN_DEFINITION_ALL_FIELDS_API,
    JSON.stringify({...approvalChain}),
    token,
    null,
    signal,
  );
};

export const RQUseGetSearchApprovalChainData = payload => {
  return useQuery(
    ['rq_get_search_approval_chain_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_SEARCH_APPROVAL_CHAIN_DEFINITION_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};
