import React from 'react';
import {RootNavigator} from './navigations';
import {App as AntdApp, ConfigProvider} from 'antd';
import {PrimaryTheme} from './style';
import configureStore from './redux/store/configureStore';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import GlobalComponent from './components/global/GlobalComponent';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {Provider} from 'react-redux';
import './App.less';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

const store = configureStore();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store = {store}>
        <ConfigProvider theme={PrimaryTheme}>
          <AntdApp>
            <GlobalComponent />
            <RootNavigator />
            <ReactQueryDevtools />
          </AntdApp>
        </ConfigProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
