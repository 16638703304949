import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {selectAuth} from '../../../../redux/selectors';
import PropTypes from 'prop-types';
import {
  RQUseGetBrandForLocationUser,
  RQUseGetBudgetTypeListData,
  RQUseGetLocationEmployeeList,
} from '../../../../reactQuery/apis';
import {Button, Card, Col, Divider, Row, Select} from 'antd';
import './SearchWallet.less';
import dayjs from 'dayjs';
import {ABM_JOB_ROLE_ID, BM_JOB_ROLE_ID, GM_JOB_ROLE_ID} from '../../../../utils/Constants';

const SearchWalletScreen = ({auth, searchPullBack}) => {
  const [searchLocation, setSearchLocation] = useState('');
  const [filter, setFilter] = useState({locationId: auth?.user?.location?.id, employeeId: auth?.user?.id});
  const [fiscalYears, setFiscalYears] = useState([]);
  const [mode, setMode] = useState([]);
  const [brandList, setBrandList] = useState([]);

  useEffect(() => {
    let month = dayjs().month();
    let year = dayjs().year();
    if (month <3){
      year =  year - 1;
    }
    let fys = [
      {key: year % 100, label:`FY ${year % 100}-${(year+1) % 100}`},
      {key: (year + 1) % 100, label:`FY ${(year + 1) % 100 }-${(year+2) % 100}`},
    ];
    setFiscalYears(fys);
    setFilter({...filter, fiscalYear: year % 100});

    let modes = [
      {key: 'SELF', label: 'FOR SELF'},
      {key: 'CHILD', label: 'FOR CHILD'},
      {key: 'BOTH', label: 'FOR BOTH'},
    ];
    setMode(modes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLocationFilter = location =>{
    if(location !== undefined) {
      var locationObj = JSON.parse(location);
      setFilter({...filter, locationId: locationObj.locationId, employeeId: locationObj.employeeId});
      setBrandList([]);
    }else{
      setFilter({...filter, locationId: auth.user?.location?.id, employeeId: auth.user?.id});
      setBrandList([]);
    }
  };

  RQUseGetBrandForLocationUser(
    {locationId: filter.locationId, userId: filter.employeeId, token: auth?.token},
    {enabled: (filter.locationId !== undefined && filter.locationId !== ''),
      onSuccess: brandData => {
        setBrandList(brandData);
      },
    });

  const {data:locationEmployeeList} = RQUseGetLocationEmployeeList({locationId: auth?.user?.location?.id, token: auth?.token});
  const {data:budgetType} = RQUseGetBudgetTypeListData({token: auth?.token});
  return(
    <Card className={'card-pullBack-container'}>
      <Divider orientation={'left'}>Search Wallets</Divider>
      <Row gutter={24}>
        <Col span={3}>
          <Select
            className={'select-location'}
            placeholder={'Select Financial Year'}
            value={filter.fiscalYear}
            onChange={value => setFilter({...filter, fiscalYear: value})}
          >
            {fiscalYears?.map((fy, index) =>(
              <Select.Option key={index} value={fy.key}>{fy.label}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={3}>
          <Select
            placeholder={'Select Brand'}
            className={'select-location'}
            onChange={value => setFilter({...filter, brandId: value})}
            allowClear
          >
            {brandList?.map((brand, index) =>(
              <Select.Option key={index} value={brand.id}>{brand.name}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={3}>
          <Select
            className={'select-location'}
            placeholder={'Budget Type'}
            onChange={value => setFilter({...filter, budgetTypeId: value})}
            allowClear
          >
            {
              budgetType?.map((brand, index) => (
                <Select.Option key={index} value={brand?.id}>{brand?.name}</Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col span={6}>
          {auth?.user?.jobRole?.id !== BM_JOB_ROLE_ID && (
            <Select
              className={'select-location'}
              placeholder={'Select Location'}
              onChange={ value => setLocationFilter(value)}
              filterOption={(input, option) =>
                option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
              showSearch={true}
              mode={'single'}
              allowClear={true}
              onSearch={value => setSearchLocation(value)}
              searchValue={searchLocation}
            >
              {locationEmployeeList?.filter(
                emp => emp.jobTitleId === BM_JOB_ROLE_ID || emp.jobTitleId === GM_JOB_ROLE_ID || emp.jobTitleId === ABM_JOB_ROLE_ID,
              ) .map((location, index) => (
                <Select.Option key={index} value={JSON.stringify(location)}>{`${location?.employeeName} - ${location?.locationName}`}</Select.Option>
              ))
              }
            </Select>
          )}
          {auth?.user?.jobRole?.id === BM_JOB_ROLE_ID && (
            <Select
              className={'select-location'}
              placeholder={'Select Location'}
              onChange={ value => setLocationFilter(value)}
              filterOption={(input, option) =>
                option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
              showSearch={true}
              mode={'single'}
              allowClear={true}
              onSearch={value => setSearchLocation(value)}
              searchValue={searchLocation}
            >
              {locationEmployeeList?.filter(
                emp => emp.jobTitleId === GM_JOB_ROLE_ID || emp.jobTitleId === ABM_JOB_ROLE_ID,
              ) .map((location, index) => (
                <Select.Option key={index} value={JSON.stringify(location)}>{`${location?.employeeName} - ${location?.locationName}`}</Select.Option>
              ))
              }
            </Select>
          )}
        </Col>
        <Col span={3}>
          <Select
            className={'select-location'}
            placeholder={'Select For'}
            onChange={value => setFilter({...filter, type: value})}
          >
            {mode?.map((md, index) =>(
              <Select.Option key={index} value={md.key}>{md.label}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={2}>
          <Button
            className={'select-location'}
            type={'primary'}
            htmlType={'submit'}
            onClick={() => searchPullBack(filter)}
          >Search</Button>
        </Col>
      </Row>
    </Card>
  );
};

SearchWalletScreen.propTypes = {
  auth: PropTypes.object.isRequired,
  searchPullBack: PropTypes.func.isRequired,
};
const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(SearchWalletScreen);
