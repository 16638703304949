import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {
  RQ_APPROVE_REJECT_VENDOR,
  RQ_GET_SEARCH_VENDOR_REGISTER_API,
  RQ_GET_VENDOR_FIND_BY_ID_API,
  RQ_RESUBMIT_VENDOR_API,
  RQ_VENDOR_REGISTER_ALL_FIELDS_API,
} from './constants';

export const RQPostVendorRegisterAllFields = async ({
  profileInformation,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_VENDOR_REGISTER_ALL_FIELDS_API,
    JSON.stringify({...profileInformation}),
    token,
    null,
    signal,
  );
};

export const RQGetSearchVendorData = payload => {
  return useQuery(
    ['rq_get_search_vendor_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_SEARCH_VENDOR_REGISTER_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQUseGetRegisteredVendorByIdData = (payload, options={}) => {
  return useQuery(
    ['rq_get_vendor_find_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {token, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_VENDOR_FIND_BY_ID_API,
        null,
        token,
        {id:id},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQVendorApproveReject = async ({
  details,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_APPROVE_REJECT_VENDOR,
    JSON.stringify({...details}),
    token,
    null,
    signal,
  );
};

export const RQVendorResubmit = async ({
  vendorDetails,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_RESUBMIT_VENDOR_API,
    JSON.stringify({...vendorDetails}),
    token,
    null,
    signal,
  );
};
