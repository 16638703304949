import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {
  RQ_CREATE_USER_API,
  RQ_GET_SEARCH_USER_API,
  RQ_GET_USER_FIND_BY_ID,
  RQ_GET_USER_LIST_API,
  RQ_UPDATE_USER_ALL_FIELDS_API,
} from './constants';
import {useQuery} from '@tanstack/react-query';

export const RQPostCreateUser = async ({
  user,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_USER_API,
    JSON.stringify({...user}),
    token,
    null,
    signal,
  );
};

export const RQUseGetUserListData = payload => {
  return useQuery(
    ['rq_get_user_list_data', payload],
    async ({queryKey, signal}) => {
      const {token} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_USER_LIST_API,
        null,
        token,
        null,
        signal,
      );
    },
    {
      cacheTime: 2 * 60 * 1000, // 2 min
    },
  );
};

export const RQUseGetSearchUserData = payload => {
  return useQuery(
    ['rq_get_search_user_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_SEARCH_USER_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQUseGetUserFindById = payload => {
  return useQuery(
    ['rq_get_user_find_by_id', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_USER_FIND_BY_ID,
        null,
        token,
        {id:id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQPutUserUpdateAllField = async ({
  user,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_USER_ALL_FIELDS_API,
    JSON.stringify({...user}),
    token,
    null,
    signal,
  );
};
