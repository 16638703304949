export const RQ_CREATE_BUDGET_FREEZE_CONFIGURATION_API = {
  url: 'v2/budget-freeze-configuration/create',
  method: 'POST',
  auth: true,
};

export const RQ_GET_BUDGET_FREEZE_CONFIGURATION_LIST_API = {
  url: 'v2/budget-freeze-configuration/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_BUDGET_FREEZE_CONFIGURATION_BY_ID_API = {
  url: 'v2/budget-freeze-configuration/findById/<%= id %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_UPDATE_BUDGET_FREEZE_CONFIGURATION_ALL_FIELDS_API = {
  url: 'v2/budget-freeze-configuration/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_SEARCH_BUDGET_FREEZE_CONFIGURATION_API = {
  url: 'v2/budget-freeze-configuration/search?',
  method: 'GET',
  search: true,
  auth: true,
};
