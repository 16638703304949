import React from 'react';
import {Col, Row} from 'antd';
import {WelcomeHeader} from './WelcomeHeader';
import './HeaderLayout.less';
import {LogoutButton} from './LogoutButton';

const HeaderLayout = () => {
  return (
    <Row className={'main-header-container'}>
      <Col span={20}>
        <WelcomeHeader />
      </Col>
      <Col>
        <LogoutButton />
      </Col>
    </Row>
  );
};

export default HeaderLayout;
