import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {
  RQ_BULK_APPROVAL_VENDOR_EVENT, RQ_CAN_CREATE_NEW_VENDOR_EVENT,
  RQ_GET_CONFERENCE_VENDOR_LIST_BY_NAME_API,
  RQ_GET_VENDOR_EVENT_BY_ID_API,
  RQ_GET_VENDOR_EVENT_CREATION_LAST_FY_EVENTS_API,
  RQ_GET_VENDOR_EVENT_CREATION_LIST_API,
  RQ_GET_VENDOR_EVENT_CREATION_SEARCH_API,
  RQ_GET_VENDOR_EVENT_REPORT_BY_EVENT_ID_API,
  RQ_GET_VENDOR_EVENT_SPONSOR_DETAILS_BY_ID_API,
  RQ_MARK_VENDOR_EVENT_ABORTED_BY_ID_API,
  RQ_MARK_VENDOR_EVENT_CONDUCTED_BY_ID_API,
  RQ_MARK_VENDOR_EVENT_RESCHEDULED_BY_ID_API,
  RQ_REPORT_VENDOR_EVENT_CLOSURE,
  RQ_UPDATE_VENDOR_EVENT_ADVANCE_API,
  RQ_VALIDATE_VENDOR_EVENT_API,
  RQ_VENDOR_EVENT_CREATION_API,
  RQ_VENDOR_EVENT_UPDATE_API,
} from './constants';

export const RQUseGetConferenceVendorListByNameData = (payload, options = {}) => {
  return useQuery(
    ['rq_get_conference_vendor_list_by_name_data', payload],
    async ({queryKey, signal}) => {
      const {filter,  token} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_CONFERENCE_VENDOR_LIST_BY_NAME_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQPostVendorEventCreation = async ({
  eventCreation,
  token,
  signal,
}) => {
  console.log(eventCreation);
  return rqCreateRequest(
    RQ_VENDOR_EVENT_CREATION_API,
    JSON.stringify({...eventCreation}),
    token,
    null,
    signal,
  );
};

export const RQUseGetVendorEventCreationListData = payload => {
  return useQuery(
    ['rq_get_vendor_event_creation_list_data', payload],
    async ({queryKey, signal}) => {
      const {token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_VENDOR_EVENT_CREATION_LIST_API,
        null,
        token,
        null,
        signal,
      );
    },
    {
      cacheTime: 2 * 60 * 1000, // 2 min
    },
  );
};

export const RQUseGetVendorEventByIdData = (payload, options = {}) => {
  return useQuery(
    ['rq_get_vendor_event_creation_list_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_VENDOR_EVENT_BY_ID_API,
        null,
        token,
        {id: id},
        signal,
      );
    },
    {
      cacheTime: 2 * 60 * 1000, // 2 min
      ...options,
    },
  );
};

export const RQUseGetVendorEventReportByIdData = (payload, options = {}) => {
  return useQuery(
    ['rq_get_vendor_event_report_list_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_VENDOR_EVENT_REPORT_BY_EVENT_ID_API,
        null,
        token,
        {id: id},
        signal,
      );
    },
    {
      cacheTime: 2 * 60 * 1000, // 2 min
      ...options,
    },
  );
};

export const RQVendorEventMarkConducted = async ({
  id,
  conductedDateTime,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_MARK_VENDOR_EVENT_CONDUCTED_BY_ID_API,
    {},
    token,
    {vendorEventId: id, conductedDateTime: conductedDateTime},
    signal,
  );
};

export const RQUseGetVendorEventSponsorByIdData = (payload, options={}) => {
  return useQuery(
    ['rq_get_event_creation_speaker_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_VENDOR_EVENT_SPONSOR_DETAILS_BY_ID_API,
        null,
        token,
        {id: id},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQReportVendorEventClosure = async ({
  data,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REPORT_VENDOR_EVENT_CLOSURE,
    JSON.stringify({...data}),
    token,
    null,
    signal,
  );
};

export const RQEVendorventMarkAborted = async ({
  id,
  abortedReason,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_MARK_VENDOR_EVENT_ABORTED_BY_ID_API,
    {abortedReason: abortedReason},
    token,
    {eventId: id},
    signal,
  );
};

export const RQVendorEventMarkReschedule = async ({
  id,
  rescheduledDateTime,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_MARK_VENDOR_EVENT_RESCHEDULED_BY_ID_API,
    {},
    token,
    {vendorEventId: id, rescheduledDateTime : rescheduledDateTime},
    signal,
  );
};

export const RQReportReSubmitVendorEvent = async ({
  data,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_VENDOR_EVENT_UPDATE_API,
    JSON.stringify({...data}),
    token,
    null,
    signal,
  );
};

export const RQVendorEventValidate = async ({
  id,
  details,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_VALIDATE_VENDOR_EVENT_API,
    JSON.stringify({...details}),
    token,
    {id: id},
    signal,
  );
};

export const RQVendorEventUpdateAdvance = async ({
  id,
  details,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_VENDOR_EVENT_ADVANCE_API,
    JSON.stringify({...details}),
    token,
    {id: id},
    signal,
  );
};

export const RQUseGetSearchVendorEventCreationSearchData = payload => {
  return useQuery(
    ['rq_get_search_vendor_event_creation_search_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_VENDOR_EVENT_CREATION_SEARCH_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQBulkApproveVendorEvent = async ({
  id,
  eventList,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_BULK_APPROVAL_VENDOR_EVENT,
    eventList,
    token,
    {},
    signal,
  );
};

export const RQCanCreateVendorEvent =  (payload, options = {}) => {
  return useQuery(
    ['rq_can_create_vendor_event', payload],
    async ({queryKey, signal}) => {
      const {locationId, token} = queryKey[1];

      return rqCreateRequest(
        RQ_CAN_CREATE_NEW_VENDOR_EVENT,
        null,
        token,
        {locationId: locationId},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQUseGetVendorEventCreationLastFYEventsData = (payload, options={}) => {
  return useQuery(
    ['rq_get_vendor_event_creation_last_fy_events_data', payload],
    async ({queryKey, signal}) => {
      const {userId, locationId, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_VENDOR_EVENT_CREATION_LAST_FY_EVENTS_API,
        null,
        token,
        {userId: userId, locationId: locationId},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};
