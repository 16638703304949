export const RQ_CREATE_SECURITY_ROLE_API = {
  url: 'v1/security-role/create',
  method: 'POST',
  auth: true,
};

export const RQ_GET_SECURITY_ROLE_LIST_API = {
  url: 'v1/security-role/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_SECURITY_ROLE_BY_ID_API = {
  url: 'v1/security-role/<%= roleId %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_UPDATE_SECURITY_ROLE_ALL_FIELDS_API = {
  url: 'v1/security-role/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_SEARCH_SECURITY_ROLE_API = {
  url: 'v1/security-role/search?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_PRIVILEGE_LIST_API = {
  url: 'v1/security-privilege/all',
  method: 'GET',
  auth: true,
};
