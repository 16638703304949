import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {
  ROUTE_ADD_EVENT_CONFIGURATION,
  ROUTE_EDIT_EVENT_CONFIGURATION,
  ROUTE_EVENT_CONFIGURATION,
  ROUTE_VIEW_EVENT_CONFIGURATION,
} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const EventConfiguration = lazy(() =>
  import('../../../screens/EventConfiguration')
    .then(module => ({default: module.EventConfigurations})));

const AddEventConfiguration = lazy(()=>
  import('../../../screens/EventConfiguration')
    .then(module => ({default: module.CreateEventConfiguration})));

const EditEventConfiguration = lazy(()=>
  import('../../../screens/EventConfiguration')
    .then(module => ({default: module.EditEventConfiguration})));

const ViewEventConfiguration = lazy(()=>
  import('../../../screens/EventConfiguration')
    .then(module => ({default: module.ViewEventConfiguration})));
const EventConfigurationNavigation = [
  {
    path: ROUTE_EVENT_CONFIGURATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EventConfiguration />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_EVENT_CONFIGURATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddEventConfiguration />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_EVENT_CONFIGURATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditEventConfiguration />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_EVENT_CONFIGURATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewEventConfiguration />
      </Suspense>
    ),
  },
];

export default EventConfigurationNavigation;
