import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {
  RQ_CREATE_LOCATION_API, RQ_GET_LOCATION_EMPLOYEE_LIST_API,
  RQ_GET_LOCATION_FIND_BY_ID,
  RQ_GET_LOCATION_FIND_BY_TYPE_ID,
  RQ_GET_LOCATION_LIST_API,
  RQ_GET_LOCATION_TYPE_LIST_API,
  RQ_GET_SEARCH_LOCATION_API,
  RQ_UPDATE_LOCATION_ALL_FIELDS_API,
} from './constants';

export const RQPostCreateLocation = async ({
  location,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_LOCATION_API,
    JSON.stringify({...location}),
    token,
    null,
    signal,
  );
};

export const RQUseGetLocationListData = payload => {
  return useQuery(
    ['rq_get_location_list_data', payload],
    async ({queryKey, signal}) => {
      const {token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_LOCATION_LIST_API,
        null,
        token,
        null,
        signal,
      );
    },
    {
      cacheTime: 2 * 60 * 1000, // 2 min
    },
  );
};

export const RQUseGetLocationFindByTypeId = payload => {
  return useQuery(
    ['rq_get_location_find_by_type_id', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_LOCATION_FIND_BY_TYPE_ID,
        null,
        token,
        {typeId:id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQUseGetLocationFindById = payload => {
  return useQuery(
    ['rq_get_location_find_by_id', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_LOCATION_FIND_BY_ID,
        null,
        token,
        {id:id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQPutLocationUpdateAllField = async ({
  location,
  token,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_LOCATION_ALL_FIELDS_API,
    JSON.stringify({...location, id:id}),
    token,
    null,
    signal,
  );
};

export const RQUseGetLocationTypeListData = payload => {
  return useQuery(
    ['rq_get_location_type_list_data', payload],
    async ({queryKey, signal}) => {
      const {token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_LOCATION_TYPE_LIST_API,
        null,
        token,
        null,
        signal,
      );
    },
    {
      cacheTime: 2 * 60 * 1000, // 2 min
    },
  );
};

export const RQUseGetSearchLocationData = payload => {
  return useQuery(
    ['rq_get_search_location_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_SEARCH_LOCATION_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQUseGetLocationEmployeeList = payload => {
  return useQuery(
    ['rq_get_location_employee_list', payload],
    async ({queryKey, signal}) => {
      const {locationId, token} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_LOCATION_EMPLOYEE_LIST_API,
        null,
        token,
        {locationId:locationId},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};
