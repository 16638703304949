
export const RQ_GET_CONFERENCE_VENDOR_LIST_BY_NAME_API = {
  url: 'v2/conference-vendor/search?',
  method: 'GET',
  auth: true,
  search:true,
};

export const RQ_GET_VENDOR_LIST_FOR_BILL_BY_NAME_API = {
  url: 'v2/conference-vendor/search-for-bill?',
  method: 'GET',
  auth: true,
  search:true,
};

export const RQ_VENDOR_EVENT_CREATION_API = {
  url: 'v2/vendor-event-creation/create',
  method: 'POST',
  auth: true,
};

export const RQ_GET_CONFERENCE_VENDOR_LIST_API = {
  url: 'v2/conference-vendor/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_VENDOR_EVENT_CREATION_LIST_API = {
  url: 'v2/vendor-event-creation/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_VENDOR_EVENT_BY_ID_API = {
  url: 'v2/vendor-event-creation/findById/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_APPROVE_REJECT_VENDOR_EVENT_API = {
  url: 'v2/vendor-event-creation/approve-reject',
  method: 'PUT',
  auth: true,
};

export const RQ_FT_APPROVE_REJECT_VENDOR_EVENT_API = {
  url: 'v2/vendor-event-report/ft-event-action-with-payment',
  method: 'PUT',
  auth: true,
};

export const RQ_MARK_VENDOR_EVENT_CONDUCTED_BY_ID_API = {
  url: 'v2/vendor-event-creation/mark-conducted/<%= vendorEventId %>/<%= conductedDateTime %>',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_VENDOR_EVENT_SPONSOR_DETAILS_BY_ID_API = {
  url: 'v2/event-sponsor/details/<%= id %>',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_REPORT_VENDOR_EVENT_CLOSURE = {
  url: 'v2/vendor-event-report/report',
  method: 'PUT',
  auth: true,
};

export const RQ_MARK_VENDOR_EVENT_ABORTED_BY_ID_API = {
  url: 'v2/vendor-event-creation/mark-aborted/<%= eventId %>',
  method: 'PUT',
  auth: true,
};

export const RQ_MARK_VENDOR_EVENT_RESCHEDULED_BY_ID_API = {
  url: 'v2/vendor-event-creation/mark-rescheduled/<%= vendorEventId %>/<%= rescheduledDateTime %>',
  method: 'PUT',
  auth: true,
};

export const RQ_VENDOR_EVENT_UPDATE_API = {
  url: 'v2/vendor-event-creation/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_VENDOR_EVENT_REPORT_BY_EVENT_ID_API = {
  url: 'v2/vendor-event-report/for-event/<%= id %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_VALIDATE_VENDOR_EVENT_API = {
  url: 'v2/vendor-event-creation/validate/<%= id %>',
  method: 'PUT',
  auth: true,
};

export const RQ_UPDATE_VENDOR_EVENT_ADVANCE_API = {
  url: 'v2/vendor-event-creation/updateAdvancePayments/<%= id %>',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_VENDOR_EVENT_CREATION_SEARCH_API = {
  url: 'v2/vendor-event-creation/search?',
  method: 'GET',
  auth: true,
  search:true,
};

export const RQ_BULK_APPROVAL_VENDOR_EVENT = {
  url: 'v2/vendor-event-creation/bulk-event-approve',
  method: 'POST',
  auth: true,
};

export const RQ_CAN_CREATE_NEW_VENDOR_EVENT = {
  url: 'v2/vendor-event-creation/can-create/<%= locationId %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_VENDOR_EVENT_CREATION_LAST_FY_EVENTS_API = {
  url: 'v2/vendor-event-creation/last-fy-events/<%= userId %>/<%= locationId %>',
  method: 'GET',
  search: true,
  auth: true,
};
