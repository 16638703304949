import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {useQuery} from '@tanstack/react-query';
import {
  RQ_CREATE_BUDGET_TYPE_API,
  RQ_GET_BUDGET_TYPE_BY_ID_API,
  RQ_GET_BUDGET_TYPE_LIST_API,
  RQ_GET_SEARCH_BUDGET_TYPE_API,
  RQ_UPDATE_BUDGET_TYPE_ALL_FIELDS_API,
} from './constants';

export const RQPostCreateBudgetType = async ({
  budget,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_BUDGET_TYPE_API,
    JSON.stringify({...budget}),
    token,
    null,
    signal,
  );
};

export const RQUseGetBudgetTypeListData = payload => {
  return useQuery(
    ['rq_get_budget_list_data', payload],
    async ({queryKey, signal}) => {
      const {token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_BUDGET_TYPE_LIST_API,
        null,
        token,
        null,
        signal,
      );
    },
    {
      cacheTime: 2 * 60 * 1000, // 2 min
    },
  );
};

export const RQUseGetBudgetTypeByIdData = payload => {
  return useQuery(
    ['rq_get_budget_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_BUDGET_TYPE_BY_ID_API,
        null,
        token,
        {id:id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQPutBudgetTypeUpdateAllField = async ({
  budget,
  token,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_BUDGET_TYPE_ALL_FIELDS_API,
    JSON.stringify({...budget, id:id}),
    token,
    null,
    signal,
  );
};

export const RQUseGetSearchBudgetTypeData = payload => {
  return useQuery(
    ['rq_get_search_budget_type_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_SEARCH_BUDGET_TYPE_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};
