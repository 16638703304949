import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {
  ROUTE_ADD_SECURITY_ROLE,
  ROUTE_SECURITY_ROLE,
  ROUTE_UPDATE_SECURITY_ROLE,
} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const SecurityRole = lazy(() =>
  import('../../../screens/SecurityRole')
    .then(module => ({default: module.SecurityRole})));

const AddSecurityRole = lazy(() =>
  import('../../../screens/SecurityRole')
    .then(module => ({default: module.CreateSecurityRole})));

const UpdateSecurityRole = lazy(() =>
  import('../../../screens/SecurityRole')
    .then(module => ({default: module.UpdateSecurityRole})));

const SecurityRoleNavigation = [
  {
    path: ROUTE_SECURITY_ROLE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <SecurityRole />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_SECURITY_ROLE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddSecurityRole />
      </Suspense>
    ),
  },
  {
    path: ROUTE_UPDATE_SECURITY_ROLE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UpdateSecurityRole />
      </Suspense>
    ),
  },
];

export default SecurityRoleNavigation;
