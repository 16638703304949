import {notification} from 'antd';
import {CheckCircleFilled, CloseCircleFilled, CloseCircleOutlined} from '@ant-design/icons';
export const showSuccessFlashMessage = (msg = '') => {
  //notification.success({content: msg}).then();
  notification.success({
    message: msg,
    style: {
      width: 600,
      minWidth: 320,
      maxWidth: 568,
      backgroundColor: '#F6FFED',
      border: '1px solid #B7EB8F',
      boxShadow: 'unset',
      top: 300,
      bottom: 300,
      right: 200,
    },
    icon: <CheckCircleFilled style={{color: '#52C51A'}} />,
    duration: 4,
  });

};

export const showErrorFlashMessage = (msg = '') => {
  notification.error({
    message: msg,
    style: {
      width: 600,
      minWidth: 320,
      maxWidth: 568,
      backgroundColor: '#ffaaaa',
      border: '1px solid #ffa39e',
      boxShadow: 'unset',
      top: 300,
      bottom: 300,
      right: 200,
    },
    icon: <CloseCircleFilled style={{color: '#f5222e'}} />,
    duration: 4,
  });
};

export const showWarningFlashMessage = (msg = '') => {
  notification.warning({content: msg}).then();
};

export const showErrorFlashMessageWithoutAutoClose = (msg = '') => {
  notification.error({
    icon: <CloseCircleOutlined />,
    message: msg,
    style:{backgroundColor: 'red'},
    duration: 60,
  });
};
