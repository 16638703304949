import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {
  ROUTE_ADD_VENDOR_CONFERENCE_EVENT,
  ROUTE_APPROVE_REJECT_VENDOR_CONFERENCE_EVENT,
  ROUTE_APPROVE_REJECT_VENDOR_EVENT_CLOSURE, ROUTE_APPROVE_REJECT_VENDOR_EVENT_CLOSURE_FINANCE,
  ROUTE_EDIT_VENDOR_EVENT_CREATION,
  ROUTE_REPORT_CLOSE_VENDOR_EVENT_CREATION, ROUTE_RESUBMIT_CLOSURE_VENDOR_EVENT_CREATION,
  ROUTE_UPDATE_ADVANCE_PAYMENT_VENDOR_FINANCE,
  ROUTE_VENDOR_CONFERENCE_EVENT,
  ROUTE_VIEW_VENDOR_EVENT_CREATION,
  ROUTE_VIEW_VENDOR_EVENT_VALIDATE,
} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const VendorConferenceEvent = lazy(() =>
  import('../../../screens/VendorConferenceEvent')
    .then(module => ({default: module.VendorConferenceEvent})));

const AddVendorConferenceEvent = lazy(() =>
  import('../../../screens/VendorConferenceEvent')
    .then(module => ({default: module.AddVendorConferenceEvent})));

const ApproveRejectVendorConferenceEvent =  lazy(() =>
  import('../../../screens/VendorConferenceEvent')
    .then(module => ({default: module.ApproveRejectVendorEventCreation})));

const ReportCloseVendorEventCreation = lazy(() =>
  import('../../../screens/VendorConferenceEvent')
    .then(module => ({default: module.ReportCloseVendorEventCreation})));

const ResubmitCreateVendorConferenceEvent =  lazy(() =>
  import('../../../screens/VendorConferenceEvent')
    .then(module => ({default: module.ResubmitCreateVendorConferenceEvent})));

const ViewVendorEventCreation =  lazy(() =>
  import('../../../screens/VendorConferenceEvent')
    .then(module => ({default: module.ViewVendorEventCreation})));

const ApproveRejectVendorEventClosure =  lazy(() =>
  import('../../../screens/VendorConferenceEvent')
    .then(module => ({default: module.ApproveRejectVendorEventClosure})));

const ValidateVendorEventCreationFinance =  lazy(() =>
  import('../../../screens/VendorConferenceEvent')
    .then(module => ({default: module.ValidateVendorEventCreationFinance})));

const UpdateAdvancePaymentVendorEventFinance =
    lazy(() =>
      import('../../../screens/VendorConferenceEvent')
        .then(module => ({default: module.UpdateAdvancePaymentVendorEventFinance})));

const ApproveRejectVendorEventClosureFinance = lazy(() =>
  import('../../../screens/VendorConferenceEvent')
    .then(module => ({default: module.ApproveRejectVendorEventClosureFinance})));

const VendorConferenceEventNavigation = [
  {

    path: ROUTE_VENDOR_CONFERENCE_EVENT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <VendorConferenceEvent />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_VENDOR_CONFERENCE_EVENT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddVendorConferenceEvent />
      </Suspense>
    ),
  },
  {
    path: ROUTE_APPROVE_REJECT_VENDOR_CONFERENCE_EVENT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ApproveRejectVendorConferenceEvent />
      </Suspense>
    ),
  },
  {
    path: ROUTE_REPORT_CLOSE_VENDOR_EVENT_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ReportCloseVendorEventCreation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_VENDOR_EVENT_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ResubmitCreateVendorConferenceEvent />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_VENDOR_EVENT_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewVendorEventCreation />
      </Suspense>
    ),

  },
  {
    path: ROUTE_APPROVE_REJECT_VENDOR_EVENT_CLOSURE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ApproveRejectVendorEventClosure />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_VENDOR_EVENT_VALIDATE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ValidateVendorEventCreationFinance />
      </Suspense>
    ),
  },
  {
    path: ROUTE_UPDATE_ADVANCE_PAYMENT_VENDOR_FINANCE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UpdateAdvancePaymentVendorEventFinance />
      </Suspense>
    ),
  },
  /*{
    path: ROUTE_UPDATE_ADVANCE_PAYMENT_VENDOR_FINANCE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ValidateVendorEventCreationFinance />
      </Suspense>
    ),
  },*/
  {
    path: ROUTE_APPROVE_REJECT_VENDOR_EVENT_CLOSURE_FINANCE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ApproveRejectVendorEventClosureFinance />
      </Suspense>
    ),
  },
  {
    path: ROUTE_RESUBMIT_CLOSURE_VENDOR_EVENT_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ReportCloseVendorEventCreation />
      </Suspense>
    ),
  },

  //
];

export default VendorConferenceEventNavigation;
