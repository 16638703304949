export const RQ_CREATE_EVENT_CREATION_API = {
  url: 'v2/event-creation/create',
  method: 'POST',
  auth: true,
};

export const RQ_GET_EVENT_CREATION_API = {
  url: 'v2/event-creation/search?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_EVENT_CREATION_ALL_API = {
  url: 'v2/event-creation/all',
  method: 'GET',
  auth: true,
};

export const RQ_APPROVE_REJECT_EVENT_API = {
  url: 'v2/event-creation/approve-reject',
  method: 'PUT',
  auth: true,
};

export const RQ_FT_APPROVE_REJECT_EVENT_API = {
  url: 'v2/event-report/ft-event-action-with-payment',
  method: 'PUT',
  auth: true,
};

export const RQ_VALIDATE_EVENT_API = {
  url: 'v2/event-creation/validate/<%= id %>',
  method: 'PUT',
  auth: true,
};

export const RQ_SAVE_PAYMENTS_API = {
  url: 'v2/event-report/save-payments/<%= id %>',
  method: 'POST',
  auth: true,
};

export const RQ_SAVE_VENDOR_PAYMENTS_API = {
  url: 'v2/vendor-event-report/save-payments/<%= id %>',
  method: 'POST',
  auth: true,
};

export const RQ_GET_PAYMENTS_API = {
  url: 'v2/event-report/get-payments/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_ADVANCE_API = {
  url: 'v2/event-creation/updateAdvancePayments/<%= id %>',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_EVENT_CREATION_FIND_BY_API = {
  url: 'v2/event-creation/findById/<%= id %>',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_EVENT_BILL_INFO_BY_EVENT_ID_API = {
  url: 'v2/bill-info/<%= eventType %>/<%= id %>',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_EVENT_UPDATE_API = {
  url: 'v2/event-creation/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_EVENT_SPEAKER_DETAILS_BY_ID_API = {
  url: 'v2/event-speaker/details/<%= id %>',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_MARK_CONDUCTED_BY_ID_API = {
  url: 'v2/event-creation/mark-conducted/<%= eventId %>/<%= conductedDateTime %>',
  method: 'PUT',
  auth: true,
};

export const RQ_MARK_CONDUCTED_BY_ADMIN_API = {
  url: 'v2/configuration-exception/mark-conducted/byAdmin',
  method: 'POST',
  auth: true,
};

export const RQ_EXTEND_CLOSURE_DATE = {
  url: 'v2/configuration-exception/save',
  method: 'POST',
  auth: true,
};

export const RQ_MARK_RESCHEDULED_BY_ID_API = {
  url: 'v2/event-creation/mark-rescheduled/<%= eventId %>/<%= rescheduledDateTime %>',
  method: 'PUT',
  auth: true,
};

export const RQ_MARK_ABORTED_BY_ID_API = {
  url: 'v2/event-creation/mark-aborted/<%= eventId %>',
  method: 'PUT',
  auth: true,
};

export const RQ_CAN_CREATE_NEW_EVENT = {
  url: 'v2/event-creation/can-create/<%= locationId %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_EVENT_PAYMENT_INFO_BY_EVENT_ID_API = {
  url: 'v2/payment-info/<%= eventType %>/<%= id %>',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_BULK_APPROVE_EVENT = {
  url: 'v2/event-creation/bulk-event-approve',
  method: 'POST',
  auth: true,
};

export const RQ_GET_EVENT_CREATION_LAST_FY_EVENTS_API = {
  url: 'v2/event-creation/last-fy-events/<%= userId %>/<%= locationId %>',
  method: 'GET',
  search: true,
  auth: true,
};
