import {getLocalAuth} from '../../localStorage';
import {ROUTE_BASE_ROOT, ROUTE_MASTERS} from '../RoutePathConstant';
import {redirect} from 'react-router-dom';

export const loginLoader = async () => {
  const auth = await getLocalAuth();

  if (auth) {
    return redirect(ROUTE_BASE_ROOT + ROUTE_MASTERS);
  }
  return auth;
};
