import {rqCreateRequest} from './rqHttpsUtils';
import {RQ_GET_CURRENT_FY, RQ_USER_LOGIN_API} from './constants';
import {useQuery} from '@tanstack/react-query';

export const RQUserLoginAPI = ({username, password, signal}) => {
  return rqCreateRequest(
    RQ_USER_LOGIN_API,
    JSON.stringify({
      username: username,
      password: password,
    }),
    null,
    null,
    signal,
  );
};

export const RQGetCurrentFT = (payload, options = {}) => {
  return useQuery(
    ['rq_get_current_fy', payload],
    async ({queryKey, signal}) => {
      const {token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_CURRENT_FY,
        null,
        token,
        null,
        signal,
      );
    },
    {
      ...options,
      cacheTime: 0,
    },
  );
};
