export const RQ_CREATE_USER_API = {
  url: 'v1/user/create',
  method: 'POST',
  auth: true,
};

export const RQ_GET_USER_LIST_API = {
  url: 'v1/user/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_SEARCH_USER_API = {
  url: 'v1/user/search?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_USER_FIND_BY_ID = {
  url: 'v1/user/findById/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_USER_ALL_FIELDS_API = {
  url: 'v1/user/update',
  method: 'PUT',
  auth: true,
};

export const RQ_SECURITY_ROLE_ALL_FIELDS_API = {
  url: 'v1/security-role/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_USER_PROFILE_BY_ID = {
  url: 'v1/user/user-profile/<%= id %>',
  method: 'GET',
  auth: true,
};
