import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {
  RQ_CREATE_DOCUMENT_CONFIGURATION_API,
  RQ_GET_DOCUMENT_CONFIGURATION_BY_OWNER_TYPE_API,
  RQ_GET_DOCUMENT_CONFIGURATION_LIST_API,
  RQ_GET_SEARCH_DOCUMENT_CONFIGURATION_API,
  RQ_UPDATE_DOCUMENT_CONFIGURATION_ALL_FIELDS_API,
} from './constants';
import {useQuery} from '@tanstack/react-query';

export const RQPostCreateDocumentConfiguration = async ({
  documents,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_DOCUMENT_CONFIGURATION_API,
    JSON.stringify([...documents]),
    token,
    null,
    signal,
  );
};

export const RQUseGetDocumentConfigurationListData = payload => {
  return useQuery(
    ['rq_get_document_list_data', payload],
    async ({queryKey, signal}) => {
      const {token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_DOCUMENT_CONFIGURATION_LIST_API,
        null,
        token,
        null,
        signal,
      );
    },
    {
      cacheTime: 2 * 60 * 1000, // 2 min
    },
  );
};

export const RQUseGetDocumentConfigurationByOwnerTypeData = (payload, options={}) => {
  return useQuery(
    ['rq_get_document_by_owner_type_data', payload],
    async ({queryKey, signal}) => {
      const {ownerType, token, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_DOCUMENT_CONFIGURATION_BY_OWNER_TYPE_API,
        null,
        token,
        {ownerType: ownerType},
        signal,
        'application/json',
        'application/json',
        filter,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQPutDocumentConfigurationUpdateAllField = async ({
  documents,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_DOCUMENT_CONFIGURATION_ALL_FIELDS_API,
    JSON.stringify([...documents]),
    token,
    {processId: documents[0].ownerType.id},
    signal,
  );
};

export const RQUseGetSearchDocumentConfigurationData = payload => {
  return useQuery(
    ['rq_get_search_document_configuration_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_SEARCH_DOCUMENT_CONFIGURATION_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};
