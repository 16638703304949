import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {useQuery} from '@tanstack/react-query';
import {RQ_GET_FISCAL_YEAR_API, RQ_GET_MENUS_USER_ID_API} from './constants';

export const RQGetSideMenusData = (payload, options = {}) => {
  return useQuery(
    ['rq_get_side_menu_data', payload],
    async ({queryKey, signal}) => {
      const {userId, token} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_MENUS_USER_ID_API,
        null,
        token,
        {id: userId},
        signal,
      );
    },
    {
      cacheTime: 2 * 60 * 1000, // 2 min
      ...options,
    },
  );
};

export const RQCommonListData = (payload, options = {}) => {
  return useQuery(
    [payload?.name?.toLowerCase(), payload],
    async ({queryKey, signal}) => {
      const {token, userId, locationId, api, misc, filter = {}} = queryKey[1];
      return rqCreateSearchRequest(
        api,
        null,
        token,
        {...misc, userId: userId, locationId: locationId},
        signal,
        filter,
      );
    },
    {
      ...options,
      cacheTime: 2 * 60 * 1000, // 2 min
    },
  );
};

export const RQFiscalYearsData = (payload, options = {}) => {
  return useQuery(
    ['rq_fiscal_years', payload],
    async ({queryKey, signal}) => {
      const {token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_FISCAL_YEAR_API,
        null,
        token,
        null,
        signal,
        null,
      );
    },
    {
      ...options,
      cacheTime: 2 * 60 * 1000, // 2 min
    },
  );
};
