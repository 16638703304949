export const RQ_CREATE_LOCATION_API = {
  url: 'v2/location/create',
  method: 'POST',
  auth: true,
};

export const RQ_GET_LOCATION_LIST_API = {
  url: 'v2/location/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_LOCATION_FIND_BY_TYPE_ID = {
  url: 'v2/location/findBy/typeId/<%= typeId %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_LOCATION_FIND_BY_ID = {
  url: 'v2/location/findById/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_MY_TEAM_LIST_API = {
  url: 'v1/user/my-team/<%= userId %>',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_LOCATION_ALL_FIELDS_API = {
  url: 'v2/location/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_SEARCH_LOCATION_API = {
  url: 'v2/location/search?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_LOCATION_TYPE_LIST_API = {
  url: 'v2/location-type/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_LOCATION_EMPLOYEE_LIST_API = {
  url: 'v2/location/location-employee-list/<%= locationId %>',
  method: 'GET',
  auth: true,
};
