import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {
  RQ_CREATE_SECURITY_ROLE_API,
  RQ_GET_SEARCH_SECURITY_ROLE_API,
  RQ_GET_SECURITY_ROLE_BY_ID_API,
  RQ_GET_SECURITY_ROLE_LIST_API,
  RQ_UPDATE_SECURITY_ROLE_ALL_FIELDS_API,
} from './constants';
import {useQuery} from '@tanstack/react-query';

export const RQPostCreateSecurityRole = async ({
  securityRole,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_SECURITY_ROLE_API,
    JSON.stringify({...securityRole}),
    token,
    null,
    signal,
  );
};

export const RQUseGetSecurityRoleListData = payload => {
  return useQuery(
    ['rq_get_security_role_list_data', payload],
    async ({queryKey, signal}) => {
      const {token} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_SECURITY_ROLE_LIST_API,
        null,
        token,
        null,
        signal,
      );
    },
    {
      cacheTime: 2 * 60 * 1000, // 2 min
    },
  );
};

export const RQUseGetSecurityRoleByIdData = payload => {
  return useQuery(
    ['rq_get_security_role_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_SECURITY_ROLE_BY_ID_API,
        null,
        token,
        {roleId:id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQPutSecurityRoleUpdateAllField = async ({
  securityRole,
  token,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_SECURITY_ROLE_ALL_FIELDS_API,
    JSON.stringify({...securityRole, id:id}),
    token,
    null,
    signal,
  );
};

export const RQUseGetSearchSecurityRoleData = payload => {
  return useQuery(
    ['rq_get_search_security_role_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_SEARCH_SECURITY_ROLE_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};
