import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_MY_BUDGET} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const MyBudget = lazy(() =>
  import('../../../screens/MyBudget')
    .then(module => ({default: module.MyBudget})));

const MyBudgetNavigation = [
  {
    path: ROUTE_MY_BUDGET,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <MyBudget />
      </Suspense>
    ),
  },
];

export default MyBudgetNavigation;
