export const  WHITE_COLOR = '#FFF';

export const PRIMARY_COLOR = '#265DBB';
export const PRIMARY_20 = 'rgba(245,246,248,0.5)';
export const PRIMARY_50 = '#f4f7fd';
export const PRIMARY_100 = '#78A0E4';
export const PRIMARY_200 = '#78A0E4';
export const PRIMARY_300 = '#5688DD';
export const PRIMARY_400 = '#3470D6';
export const PRIMARY_500 = '#265DBB';
export const PRIMARY_600 = '#1D468C';
export const PRIMARY_700 = '#132F5E';
export const PRIMARY_800 = '#0A172F';
export const PRIMARY_900 = '#000000';

export const ORANGE_50 = '#ffeee5';
export const ORANGE_100 = '#ffccb3';
export const ORANGE_200 = '#ffaa80';
export const ORANGE_400 = '#ff661a';
export const ORANGE_700 = '#802b00';

export const GREEN_50 = '#f0f9ec';
export const GREEN_200 = '#b2e19e';
export const GREEN_300 = '#93d577';
export const GREEN_400 = '#74c950';
export const GREEN_700 = '#32611e';

export const YOUTUBE_COLOR = '#cd201f';
export const VIDEO_COLOR = '#2b6fec';
export const TEXT_COLOR = '#7c7c7c';
