export const RQ_CREATE_DIVISION_API = {
  url: 'v2/division/create',
  method: 'POST',
  auth: true,
};

export const RQ_GET_DIVISION_LIST_API = {
  url: 'v2/division/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_DIVISION_BY_ID_API = {
  url: 'v2/division/findById/<%= id %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_UPDATE_DIVISION_ALL_FIELDS_API = {
  url: 'v2/division/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_SEARCH_DIVISION_API = {
  url: 'v2/division/search?',
  method: 'GET',
  search: true,
  auth: true,
};
