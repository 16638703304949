import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_VENDOR, ROUTE_EDIT_VENDOR, ROUTE_SEARCH_VENDOR, ROUTE_VIEW_VENDOR} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const SearchVendor = lazy(() =>
  import('../../../screens/SearchVendor')
    .then(module => ({default: module.SearchVendor})));

const AddVendor = lazy(() =>
  import('../../../screens/SearchVendor')
    .then(module => ({default: module.AddVendor})));

const ViewVendor = lazy(() =>
  import('../../../screens/SearchVendor')
    .then(module => ({default: module.ViewVendor})));

const EditVendor = lazy(() =>
  import('../../../screens/SearchVendor')
    .then(module => ({default: module.EditVendor})));

const SearchVendorNavigation = [
  {
    path: ROUTE_SEARCH_VENDOR,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <SearchVendor />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_VENDOR,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddVendor />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_VENDOR,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewVendor />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_VENDOR,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditVendor />
      </Suspense>
    ),
  },
];

export default SearchVendorNavigation;
