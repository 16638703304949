//Auth APIs
export const USER_LOGOUT_API = {
  url: 'api/v1/admin/<%= userId %>/logout',
  method: 'PUT',
  auth: true,
};

export const USER_PROFILE_API = {
  url: 'v1/user/find-by-username/<%= userName %>',
  method: 'GET',
  auth: true,
};

export const USER_PRIVILEGES_API = {
  url: 'v1/user/privileges/<%= userName %>',
  method: 'GET',
  auth: true,
};

export const USER_PROFILE_UPDATE_API = {
  url: 'v2/user/update',
  method: 'PUT',
  auth: true,
};
