import {Checkbox} from 'antd';

const PullBackSearchColumns =  (auth, updateSelectedWalletBalanceMap, selectedBalanceList, approveAll) => [
  {
    title: <Checkbox key={'pullbackSelectAll'} onChange={ e => approveAll(e)}>Select All</Checkbox>,
    fixed: 'right',
    render: (_, record, index) => {
      return (
        <>
          <Checkbox key={`${record.walletBalanceId}_${index}`}
            onChange={ e =>{
              updateSelectedWalletBalanceMap(e, record.walletBalanceId);
            }}
            checked={selectedBalanceList.indexOf(record.walletBalanceId)>-1}
          />
        </>
      );
    },
  },
  {
    title: 'SR No',
    render: (_, record, index) => {
      return <span>{index + 1}</span>;
    },
    fixed: 'left',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    render: (_, record, index) => {
      return <span>{record.location.name}</span>;
    },
    fixed: 'left',
  },
  {
    title: 'Employee',
    dataIndex: 'employee',
    render: (_, record, index) => {
      return <span>{record.employee.name}</span>;
    },
    fixed: 'left',
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
    render: (_, record, index) => {
      return <span>{record.brand.name}</span>;
    },
    fixed: 'left',
  },
  {
    title: 'Budget Type',
    dataIndex: 'budgetType',
    render: (_, record, index) => {
      return <span>{record.budgetType.name}</span>;
    },
    fixed: 'left',
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    render: (_, record, index) => {
      return <span>{record.balance}</span>;
    },
    fixed: 'right',
  },
];
export default PullBackSearchColumns;
