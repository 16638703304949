export const RQ_VENDOR_REGISTER_ALL_FIELDS_API = {
  url: 'v2/conference-vendor/save',
  method: 'POST',
  auth: true,
};

export const RQ_GET_SEARCH_VENDOR_REGISTER_API = {
  url: 'v2/conference-vendor/list?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_VENDOR_FIND_BY_ID_API = {
  url: 'v2/conference-vendor/findById/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_APPROVE_REJECT_VENDOR = {
  url: 'v2/conference-vendor/approve',
  method: 'PUT',
  auth: true,
};

export const RQ_RESUBMIT_VENDOR_API = {
  url: 'v2/conference-vendor/resubmit',
  method: 'PUT',
  auth: true,
};
