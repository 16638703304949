export const RQ_USER_LOGIN_API = {
  url: 'v1/user/login',
  method: 'POST',
  auth: false,
};
export const RQ_GET_CURRENT_FY = {
  url: 'v1/user/getCurrentFY',
  method: 'GET',
  auth: false,
};
