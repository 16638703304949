import {rqCreateMultipartRequest, rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {
  RQ_GET_PULL_BACK_LIST_API, RQ_GET_PULL_BACK_TRANSACTION_DETAIL_API,
  RQ_GET_SEARCH_WALLET_UPLOAD_API, RQ_POST_GET_WALLET_BALANCE, RQ_POST_SAVE_PULL_BACK,
  RQ_POST_SERVICE_AGREEMENT_API,
  RQ_UPLOAD_WALLET_API,
  RQ_WALLET_UPLOAD_SAVE_API,
} from './constants';
import {useQuery} from '@tanstack/react-query';

export const RQPostWalletUpload = async ({
  formData,
  token,
  signal,
}) => {
  return rqCreateMultipartRequest(
    RQ_UPLOAD_WALLET_API,
    formData,
    token,
    null,
    signal,
  );
};

export const RQPostWalletUploadSave = async ({
  cellValues,
  transactionId,
  token,
  signal,
  comments,
}) => {
  return rqCreateRequest(
    RQ_WALLET_UPLOAD_SAVE_API,
    JSON.stringify({data: cellValues, walletUploadComments: comments}),
    token,
    {transactionId: transactionId},
    signal,
  );
};

export const RQUseGetSearchWalletUploadData = payload => {
  return useQuery(
    ['rq_get_search_wallet_upload_data', payload],
    async ({queryKey, signal}) => {
      const {token, uploadedBy} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_SEARCH_WALLET_UPLOAD_API,
        null,
        token,
        null,
        signal,
        {uploadedBy},
      );
    },
    {
      cacheTime: 0,
    },
  );
};

//TODO
export const RQPostServiceAgreement = async ({
  formData,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_POST_SERVICE_AGREEMENT_API,
    formData,
    token,
    null,
    signal,
  );
};

export const RQPostSearchWalletBalance = async ({
  filters,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_POST_GET_WALLET_BALANCE,
    filters,
    token,
    null,
    signal,
  );
};

export const RQPostSavePullBack = async ({
  body,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_POST_SAVE_PULL_BACK,
    body,
    token,
    null,
    signal,
  );
};

export const RQUseGetPullBackRequestData = payload => {
  return useQuery(
    ['rq_get_pullback_request_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_PULL_BACK_LIST_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQUseGetPullBackTransactionDetail = async ({
  filter,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_GET_PULL_BACK_TRANSACTION_DETAIL_API,
    {},
    token,
    {},
    signal,
    {},
    {},
    filter,
  );
};
