import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {useQuery} from '@tanstack/react-query';
import {
  RQ_CREATE_BUDGET_FREEZE_CONFIGURATION_API,
  RQ_GET_BUDGET_FREEZE_CONFIGURATION_BY_ID_API,
  RQ_GET_BUDGET_FREEZE_CONFIGURATION_LIST_API,
  RQ_GET_SEARCH_BUDGET_FREEZE_CONFIGURATION_API,
  RQ_UPDATE_BUDGET_FREEZE_CONFIGURATION_ALL_FIELDS_API,
} from './constants';

export const RQPostCreateFreezeConfiguration = async ({
  freezeAllocation,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_BUDGET_FREEZE_CONFIGURATION_API,
    JSON.stringify({...freezeAllocation}),
    token,
    null,
    signal,
  );
};

export const RQUseGetBudgetFreezeConfigurationListData = payload => {
  return useQuery(
    ['rq_get_budget_freeze_configuration_list_data', payload],
    async ({queryKey, signal}) => {
      const {token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_BUDGET_FREEZE_CONFIGURATION_LIST_API,
        null,
        token,
        null,
        signal,
      );
    },
    {
      cacheTime: 2 * 60 * 1000, // 2 min
    },
  );
};

export const RQUseGetBudgetFreezeConfigurationTypeByIdData = payload => {
  return useQuery(
    ['rq_get_budget-freeze_configuration_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_BUDGET_FREEZE_CONFIGURATION_BY_ID_API,
        null,
        token,
        {id:id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQPutBudgetFreezeAllocationUpdateAllField = async ({
  freezeAllocation,
  token,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_BUDGET_FREEZE_CONFIGURATION_ALL_FIELDS_API,
    JSON.stringify({...freezeAllocation, id:id}),
    token,
    null,
    signal,
  );
};

export const RQUseGetSearchBudgetFreezeConfigurationData = payload => {
  return useQuery(
    ['rq_get_search_budget_freeze_configuration_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_SEARCH_BUDGET_FREEZE_CONFIGURATION_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};
