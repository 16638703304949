import {redirect} from 'react-router-dom';
import {ROUTE_BASE_ROOT, ROUTE_LOGIN, ROUTE_MASTERS} from '../RoutePathConstant';
import {getLocalAuth} from '../../localStorage';

export const appIndexAuthLoader = async () => {
  const auth = await getLocalAuth();

  if (!auth?.user?.id) {
    return redirect(ROUTE_BASE_ROOT + ROUTE_LOGIN);
  } else {
    return redirect(ROUTE_BASE_ROOT + ROUTE_MASTERS);
  }
};
