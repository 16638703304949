import React from 'react';
import PropTypes from 'prop-types';
import {logoutStart} from '../../../../../redux/actions';
import {connect} from 'react-redux';
import {selectAuth} from '../../../../../redux/selectors';
import {ConfirmComponent} from '../../../../../components/common/ConfirmComponent';
import {LogoutOutlined} from '@ant-design/icons';
import './LogoutButton.less';

const LogoutButton = ({auth, handleLogoutStart}) => {
  return (
    <ConfirmComponent
      confirmMessage={'Do you want to logout?'}
      confirmButtonType={'link'}
      confirmButtonClassName={'logout-button'}
      onConfirm={() => handleLogoutStart(auth)}
      confirmStyle={{
        paddingLeft: 0,
        width: '100%',
        textAlign: 'left',
      }}
    >
          Logout
      <LogoutOutlined />
    </ConfirmComponent>
  );
};

LogoutButton.propTypes = {
  auth: PropTypes.object.isRequired,
  handleLogoutStart: PropTypes.func.isRequired,
};

const actions = {
  handleLogoutStart: logoutStart,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState, actions)(LogoutButton);
