import {CONFIGURATION_MASTER} from '../../RoutePathConstant';
import {authLoader} from '../../loader';
import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';

const ConfigurationMaster = lazy( () =>
  import('../../../screens/ConfigurationMaster').then(module => ({default: module.ConfigurationMaster})),
);

const ConfigurationMasterNavigation =[
  {
    path: CONFIGURATION_MASTER,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ConfigurationMaster />
      </Suspense>
    ),
  },
];

export default ConfigurationMasterNavigation;
