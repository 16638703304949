import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_PREVIEW_UPLOADS, ROUTE_UPLOADS} from '../../RoutePathConstant';

const BudgetUpload = lazy(() =>
  import('../../../screens/BudgetUpload')
    .then(module => ({default: module.BudgetUpload})));

const PreviewBudgetUpload = lazy(() =>
  import('../../../screens/BudgetUpload')
    .then(module => ({default: module.PreviewBudgetUpload})));

const BudgetUploadNavigation = [
  {
    path: ROUTE_UPLOADS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <BudgetUpload />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PREVIEW_UPLOADS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <PreviewBudgetUpload />
      </Suspense>
    ),
  },
];

export default BudgetUploadNavigation;
