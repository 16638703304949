import React, {useEffect, useState} from 'react';
import {MenuBuilder} from './MenuBuilder';
import {Col, Layout, Menu} from 'antd';
import * as PropTypes from 'prop-types';
import './SideMenu.less';
import {Link, useLocation} from 'react-router-dom';
import {ROUTE_BASE_ROOT, ROUTE_HOME} from '../../../../navigations/RoutePathConstant';
import {selectAuth} from '../../../../redux/selectors';
import {connect} from 'react-redux';
import {RQGetSideMenusData} from '../../../../reactQuery/apis/rqUIAPI';
import {isEmpty} from 'lodash';
import logo from '../../../IndexScreen/logo.png';

const {Sider} = Layout;

const SideMenu = ({auth, collapsed, onClick}) => {

  const {data = []} = RQGetSideMenusData({
    token: auth?.token,
    userId: auth.user.id,
  }, {
    enabled: !isEmpty(auth.user.id),
  });

  const location = useLocation();

  const [currentPath, setCurrentPath] = useState(
    location.pathname === ROUTE_BASE_ROOT || location.pathname === ''
      ? ROUTE_BASE_ROOT
      : location.pathname.split(ROUTE_BASE_ROOT)[1],
  );

  useEffect(() => {
    if (location) {
      if (currentPath !== location.pathname) {
        setCurrentPath(location.pathname);
      }
    }
  }, [location, currentPath]);

  return (
    <Sider
      className={'main-side-menu-container'}
      theme={'light'}
      trigger={null}
    >
      <Link to={ROUTE_HOME}>
        <Col className={'side-menu-logo'}>
          <img src={logo} alt={'logo'} style={{height:40, alignSelf: 'center'}} />
        </Col>
      </Link>
      <Menu
        mode={'inline'}
        defaultSelectedKeys={[currentPath]}
        items={MenuBuilder(data || [])}
      />
    </Sider>
  );
};

SideMenu.propTypes = {
  auth: PropTypes.shape({
    loggedIn: PropTypes.bool,
    user: PropTypes.any.isRequired,
    token: PropTypes.string,
  }),
  collapsed: PropTypes.bool,
  onClick: PropTypes.func,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(SideMenu);
