import {Card, Image, Space} from 'antd';
import CMEIMG from '../../../assets/CMEIMG.png';
import './MainDashboardIndex.less';

const MainDashboardIndex = () => {
  return (
    <Space direction={'horizontal'}>
      <Card>
        <Space direction={'horizontal'}>
          <Image
            preview={false}
            width={200}
            src={CMEIMG}
          />
        </Space>
      </Card>
    </Space>
  );
};

export default MainDashboardIndex;
