export const RQ_CREATE_BRAND_API = {
  url: 'v2/brand/create',
  method: 'POST',
  auth: true,
};

export const RQ_GET_BRAND_BY_ID_API = {
  url: 'v2/brand/findById/<%= id %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_BRAND_LIST_API = {
  url: 'v2/brand/all',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_BRAND_ALL_FIELDS_API = {
  url: 'v2/brand/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_SEARCH_BRAND_API = {
  url: 'v2/brand/search?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_MY_BRAND_API = {
  url: 'v2/brand/for-user/<%= userId %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_BRAND_FOR_LOCATION_USER_API = {
  url: 'v2/brand/for-location-user/<%= locationId %>/<%= userId %>',
  method: 'GET',
  auth: true,
  search: true,
};
