import {filter, switchMap} from 'rxjs/operators';
import {
  DO_NOTHING,
  HIDE_SPINNER, SHOW_ERROR,
  SHOW_SPINNER, SHOW_SUCCESS,
} from '../actionConstants';
import {messages} from './constants';
import {doNothing, hideSpinner, showMessage, showSpinner} from '../actions';
import {concat} from 'lodash';

export const globalActionsEpic = action$ =>
  action$.pipe(
    filter(action => action.type !== DO_NOTHING),
    switchMap(action => {
      let emitActions = [];
      if (messages[action.type] === undefined) {
        emitActions.push(doNothing());
      } else {
        let actions = messages[action.type];
        actions.forEach(a => {
          switch (a.action) {
            case SHOW_SPINNER:
              emitActions.push(showSpinner());
              break;
            case HIDE_SPINNER:
              emitActions.push(hideSpinner());
              break;
            case SHOW_SUCCESS:
            case SHOW_ERROR:
              emitActions.push(showMessage(a.payload));
              break;
            default:
              emitActions.push(doNothing());
          }
        });
      }
      return concat(emitActions);
    }),
  );
