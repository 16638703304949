import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_BUDGET_TYPE, ROUTE_BUDGET_TYPE, ROUTE_UPDATE_BUDGET_TYPE} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const Budget = lazy(() =>
  import('../../../screens/Budget')
    .then(module => ({default: module.Budget})));

const AddBudget = lazy(() =>
  import('../../../screens/Budget')
    .then(module => ({default: module.AddBudget})));

const UpdateBudget = lazy(() =>
  import('../../../screens/Budget')
    .then(module => ({default: module.EditBudget})));

const BudgetTypeNavigation = [
  {
    path: ROUTE_BUDGET_TYPE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Budget />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_BUDGET_TYPE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddBudget />
      </Suspense>
    ),
  },
  {
    path: ROUTE_UPDATE_BUDGET_TYPE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UpdateBudget />
      </Suspense>
    ),
  },
];

export default BudgetTypeNavigation;
