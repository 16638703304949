import {ajax} from 'rxjs/ajax';
import {isEmpty, template} from 'lodash';
import {AppConfig} from '../../../config';
import {buildHttpRequestHeader} from '../../../utils';

export const createMultipartRequest = (api, body, token, params) => {
  return create(api, body, token, params, 'multipart/form-data');
};

export const createRequest = (api, body, token, params) => {
  return create(api, body, token, params, 'application/json');
};

const create = (api, body, token = '', params, contentType) => {
  let url = api.url;
  if (params !== null && params !== undefined) {
    url = template(url)(params);
  }

  if (isEmpty(token)) {
    token = '';
  }

  const headers = buildHttpRequestHeader({}, api.auth, token, contentType);

  if (api.method === 'POST' || api.method === 'PUT') {
    return ajax({
      url: `${AppConfig.API_BASE_URL}/${url}`,
      method: api.method,
      headers: headers,
      body: body,
    });
  } else {
    return ajax({
      url: `${AppConfig.API_BASE_URL}/${url}`,
      method: api.method,
      headers: headers,
    });
  }
};
