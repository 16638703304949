import PropTypes from 'prop-types';
import {selectAuth} from '../../../redux/selectors';
import {connect} from 'react-redux';
import {Table} from 'antd';
import React from 'react';

const SearchTable = ({data, columns, dataSource}) => {
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      rowKey={'id'}
      size={'large'}
      bordered
      defaultPageSize={1}
      pagination={{defaultPageSize: 25}}
      loading={!data}
      scroll={{
        x: true,
      }}
    />
  );
};

SearchTable.propTypes = {
  data: PropTypes.array,
  dataSource: PropTypes.any,
  columns: PropTypes.any,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(SearchTable);
