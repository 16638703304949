export const RQ_CREATE_DOCUMENT_CONFIGURATION_API = {
  url: 'v2/document-configuration/create/bulk',
  method: 'POST',
  auth: true,
};

export const RQ_GET_DOCUMENT_CONFIGURATION_LIST_API = {
  url: 'v2/document-configuration/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_DOCUMENT_CONFIGURATION_BY_OWNER_TYPE_API = {
  url: 'v2/document-configuration/findByOwnerType/<%= ownerType %>?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_UPDATE_DOCUMENT_CONFIGURATION_ALL_FIELDS_API = {
  url: 'v2/document-configuration/update-all/<%= processId %>',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_SEARCH_DOCUMENT_CONFIGURATION_API = {
  url: 'v2/document-configuration/search?',
  method: 'GET',
  search: true,
  auth: true,
};
