export const RQ_CREATE_JOB_ROLE_API = {
  url: 'v2/job-role/create',
  method: 'POST',
  auth: true,
};

export const RQ_GET_JOB_ROLE_LIST_API = {
  url: 'v2/job-role/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_JOB_ROLE_BY_ID_API = {
  url: 'v2/job-role/findById/<%= id %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_UPDATE_JOB_ROLE_ALL_FIELDS_API = {
  url: 'v2/job-role/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_SEARCH_JOB_ROLE_API = {
  url: 'v2/job-role/search?',
  method: 'GET',
  search: true,
  auth: true,
};
