import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {
  ROUTE_ADD_EVENT_CREATION,
  ROUTE_APPROVE_REJECT_EVENT_CLOSURE,
  ROUTE_APPROVE_REJECT_EVENT_CLOSURE_FINANCE,
  ROUTE_APPROVE_REJECT_EVENT_CREATION,
  ROUTE_CREATE_EVENT,
  ROUTE_EDIT_EVENT_CREATION,
  ROUTE_REPORT_CLOSE_EVENT_CREATION,
  ROUTE_RESUBMIT_CLOSURE_EVENT_CREATION,
  ROUTE_UPDATE_ADVANCE_PAYMENT_EVENT_CREATION_FINANCE,
  ROUTE_VALIDATE_EVENT_CREATION_FINANCE,
  ROUTE_VIEW_EVENT_CREATION,
} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const CreateEvent = lazy(() =>
  import('../../../screens/CreateEvent')
    .then(module => ({default: module.CreateEvent})));

const AddEventCreation = lazy(() =>
  import('../../../screens/CreateEvent')
    .then(module => ({default: module.AddEventCreation})));

const ViewEventCreation = lazy(() =>
  import('../../../screens/CreateEvent')
    .then(module => ({default: module.ViewEventCreation})));

const ResubmitEventCreation = lazy(() =>
  import('../../../screens/CreateEvent')
    .then(module => ({default: module.ResubmitEventCreation})));

const ApproveRejectEventCreation = lazy(() =>
  import('../../../screens/CreateEvent')
    .then(module => ({default: module.ApproveRejectEventCreation})));

const ApproveRejectEventClosure = lazy(() =>
  import('../../../screens/CreateEvent')
    .then(module => ({default: module.ApproveRejectEventClosure})));

const ApproveRejectEventClosureFinance = lazy(() =>
  import('../../../screens/CreateEvent')
    .then(module => ({default: module.ApproveRejectEventClosureFinance})));

const ValidateEventCreationFinance = lazy(() =>
  import('../../../screens/CreateEvent')
    .then(module => ({default: module.ValidateEventCreationFinance})));

const ReportCloseEventCreation = lazy(() =>
  import('../../../screens/CreateEvent')
    .then(module => ({default: module.ReportCloseEventCreation})));

const UpdateAdvancePaymentEventCreationFinance = lazy(() =>
  import('../../../screens/CreateEvent')
    .then(module => ({default: module.UpdateAdvancePaymentEventCreationFinance})));

const CreateEventNavigation = [
  {
    path: ROUTE_CREATE_EVENT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateEvent />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_EVENT_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddEventCreation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_EVENT_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewEventCreation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_EVENT_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ResubmitEventCreation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_APPROVE_REJECT_EVENT_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ApproveRejectEventCreation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_REPORT_CLOSE_EVENT_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ReportCloseEventCreation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_APPROVE_REJECT_EVENT_CLOSURE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ApproveRejectEventClosure />
      </Suspense>
    ),
  },
  {
    path: ROUTE_APPROVE_REJECT_EVENT_CLOSURE_FINANCE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ApproveRejectEventClosureFinance />
      </Suspense>
    ),
  },
  {
    path: ROUTE_RESUBMIT_CLOSURE_EVENT_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ReportCloseEventCreation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VALIDATE_EVENT_CREATION_FINANCE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ValidateEventCreationFinance />
      </Suspense>
    ),
  },
  {
    path: ROUTE_UPDATE_ADVANCE_PAYMENT_EVENT_CREATION_FINANCE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UpdateAdvancePaymentEventCreationFinance />
      </Suspense>
    ),
  },
];

export default CreateEventNavigation;
