export const RQ_CREATE_REALLOCATION_API = {
  url: 'v2/reallocation/create',
  method: 'POST',
  auth: true,
};

export const RQ_GET_REALLOCATION_LIST_API = {
  url: 'v2/reallocation/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_REALLOCATION_MY_PENDING_LIST_API = {
  url: 'v2/reallocation/my-requests?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_REALLOCATION_PENDING_WITH_ME_LIST_API = {
  url: 'v2/reallocation/pending-with-me',
  method: 'GET',
  auth: true,
};

export const RQ_GET_REALLOCATION_BY_ID_API = {
  url: 'v2/reallocation/findById/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_REJECT_REALLOCATION_BY_ID_API = {
  url: 'v2/reallocation/approve-reject',
  method: 'PUT',
  auth: true,
};

export const RQ_APPROVE_REALLOCATION_BY_ID_API = {
  url: 'v2/reallocation/approve-reject',
  method: 'PUT',
  auth: true,
};

export const RQ_FORWARD_REALLOCATION_BY_ID_API = {
  url: 'v2/reallocation/forward',
  method: 'PUT',
  auth: true,
};
